import axios from "axios";

// const API_URL = "https://swapit.codemelodies.com/wp-json/bmaxapi/v1/connect";
const API_URL = "https://www.swapit.no/wp-json/bmaxapi/v1/connect";

// const API_URL = "http://localhost/swapit_wp/wp-json/bmaxapi/v1/connect";

const myHeaders = new Headers();
myHeaders.append("X-Api-Key", "app");
myHeaders.append("Content-Type", "application/json");
myHeaders.append("X-Api-Sec", "app");

const fetchData = async (action, additionalData = {}) => {
  const { id } = JSON.parse(localStorage.getItem("user"));
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": "app",
      "X-Api-Sec": "app",
      "X-User-Id": id,
    },
  };
  console.log("createOrder:",config)
  try {
    const response = await axios.post(`${API_URL}/create_order`, {
      action,
      ...additionalData,
    },config);

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

// Example API functions
export const createOrder = async (orderData) => fetchData("create_order", orderData);



const fetchAdminOrders = async (action, additionalData = {}) => {
  try {
    const response = await axios.post(`${API_URL}`, {
      action,
      ...additionalData,
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching admin orders data:", error);
    throw error;
  }
};
const fetchCustomerOrders = async (action, additionalData = {}, id) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": "app",
      "X-Api-Sec": "app",
      "X-User-Id": id,
    },
  };

  console.log("fetchCustomerOrders:",config)
  //pass config
  try {
    const response = await axios.post(`${API_URL}`, {
      action,
      ...additionalData,
    }, config);

    return response.data;
  } catch (error) {
    console.error("Error fetching customer order data:", error);
    throw error;
  }
};
const fetchVendorOrders = async (action, additionalData = {}, id) => {
  const config = {
    headers: {
      "X-Api-Key": "app",
      "X-Api-Sec": "app",
      "Content-Type": "application/json",
      "X-User-ID": id,
    },
  };

  console.log("fetchVendorOrders:",config)

  try {
    const response = await axios.post(`${API_URL}`, {
      action,
      ...additionalData,
    }, config);

    return response.data;
  } catch (error) {
    console.error("Error fetching customer order data:", error);
    throw error;
  }

};

// Example API functions
// export const getAdminOrders = async (orderData) => fetchAdminOrders("orders", orderData);

export const getOrders = async (orderData) => {
  const role = localStorage.getItem("role");
  
  if (role === "admin") {
    // const { id } = JSON.parse(localStorage.getItem("user"));
    return await fetchAdminOrders("admin_orders", orderData);
  }
  
  if (role === "vendor") {
    const { id } = JSON.parse(localStorage.getItem("user"));
    return await fetchVendorOrders("vendor_orders", orderData, id);
  }

  if (role === "customer") {
    const { id } = JSON.parse(localStorage.getItem("user"));
    return await fetchCustomerOrders("customer_orders", orderData, id);
  }
};

const fetchOrderById = async (action, additionalData = {}) => {
  try {
    const response = await axios.post(`${API_URL}`, {
      action,
      ...additionalData,
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

// Example API functions
export const getOrderById = async (orderData) => fetchOrderById("order_detail", orderData);

const getData = async (action, additionalData = {}) => {
  try {
    const response = await axios.post(`${API_URL}`, {
      action,
      ...additionalData,
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

// Fetch vehicle types
export const getVehicleTypes = async () => {
  return await getData("vehicle_types");
};

export const getConcretePresets = async () => {
  return await getData("concrete_presets");  // API action for fetching concrete presets
};

export const updateOrder = async (orderData) => {
  return await getData("update_order", orderData);  // API action for updating the order
};

export const updateOrderTotal = async (orderId, newTotal) => {
  try {
    const response = await axios.post(`${API_URL}`, {
      action: 'update_new_total',
      order_id: orderId,
      new_total: newTotal
    });

    return response.data;
  } catch (error) {
    console.error("Error updating order total:", error);
    throw error;
  }
};


// Function to call the API for deleting delivery
export const deleteDelivery = async (orderId, deliveryId) => {
  try {
    const response = await axios.post(`${API_URL}`, {
      action: 'delete_delivery',
      order_id: orderId,
      deliveryId: deliveryId
    });

    return response.data;  // Return the response data after deletion
  } catch (error) {
    console.error("Error deleting delivery:", error);
    throw error;  // Throw the error to be caught in the component
  }
};

// Function to call the API for deleting delivery
export const approveProject = async (orderId,vendor_id) => {
  try {
    const response = await axios.post(`${API_URL}`, {
      action: 'approved_project',
      order_id: orderId,
      vendor_id: vendor_id
    });

    return response.data;  // Return the response data after deletion
  } catch (error) {
    console.error("Error approved project:", error);
    throw error;  // Throw the error to be caught in the component
  }
};


export const changeRequestByVendor = async (orderId, changeDate, changeTime, changeDetail) => {
  try {
    const response = await axios.post(`${API_URL}`, {
      action: 'change_request_by_vendor',
        order_id: orderId,
        rcd: changeDate,    // Requested Date
        rct: changeTime,    // Requested Time
        t: changeDetail     // Vendor Note (Change Detail)
      });

    return response.data; 
  } catch (error) {
    console.error("Error change request:", error);
    throw error;  // Throw the error to be caught in the component
  }
};


export const fetchStatusList = async () => {
  try {
    const response = await axios.post(`${API_URL}`, {
      action: 'fetch_status_list',
      });

    return response.data;  
  } catch (error) {
    console.error("Error fetch status list:", error);
    throw error;  // Throw the error to be caught in the component
  }
};

export const fetchVendorList = async () => {
  try {
    const response = await axios.post(`${API_URL}`, {
      action: 'fetch_vendor_list',
      });

    return response.data;
  } catch (error) {
    console.error("Error fetch vendor list:", error);
    throw error;  // Throw the error to be caught in the component
  }
};

export const changeVendor = async (orderId, selectedVendor, orderAddress) => {
  try {
    const response = await axios.post(`${API_URL}`, {
      action: 'change_vendor',
      order_id: orderId,
      selectedVendor: selectedVendor,
      orderAddress: orderAddress
      });

    return response.data;
  } catch (error) {
    console.error("Error fetch vendor list:", error);
    throw error;  // Throw the error to be caught in the component
  }
};

export const changeOrderStatus = async (orderId, selectedStatus) => {
  try {
    const response = await axios.post(`${API_URL}`, {
      action: 'change_order_status',
      order_id: orderId,
      selectedStatus: selectedStatus
      });

    return response.data;
  } catch (error) {
    console.error("Error fetch vendor list:", error);
    throw error;  // Throw the error to be caught in the component
  }
};


export const cancelRequestByVendor = async (orderId) => {
  try {
    const response = await axios.post(`${API_URL}`, {
      action: 'cancel_request_by_vendor',
        order_id: orderId
      });

    return response.data;  // Return the response data after deletion
  } catch (error) {
    console.error("Error cancel request:", error);
    throw error;  // Throw the error to be caught in the component
  }
};

// Function to call the API for deleting delivery
export const fetchVendorDetails = async (vendor_id, address) => {
  try {
    const response = await axios.post(`${API_URL}`, {
      action: 'get_vendor',
      vendor_id: vendor_id,
      address: address
    });

    return response.data;  // Return the response data after deletion
    console.error('vendor response.data:', response.data);
  } catch (error) {
    console.error('Error fetching vendor details:', error);
    throw error;  // Throw the error to be caught in the component
  }
};

// Function to call the API for deleting delivery
export const getCustomConcrete = async () => {
  try {
    const response = await axios.post(`${API_URL}`, {
      action: 'get_custom_concrete'
    });

    return response.data;  // Return the response data after deletion
    console.log('custom concrete response.data:', response.data);
  } catch (error) {
    console.error('Error fetching custom concrete details:', error);
    throw error;  // Throw the error to be caught in the component
  }
};


export const profile = async (action, additionalData = {}, userId) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": "app",
      "X-Api-Sec": "app",
      "X-User-Id": userId,
    },
  };

  console.log("profile:",config)
  //pass config
  try {
    const response = await axios.post(`${API_URL}`, {
      action,
      ...additionalData,
    }, config);

    return response.data;
  } catch (error) {
    console.error("Error fetching profile data:", error);
    throw error;
  }
};


export const getDashboardStatistic = async (orderData) => {
  const statistic = {};

  const data = await getOrders(orderData);

  if (data.status) {
    statistic.status = true;
    statistic.totalOrders = data.data.length;
  } else {
    statistic.status = false;
  }

  return statistic;
};
