import React from "react";
import { Box, Typography, Link, useTheme } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import App_logo from "assets/images/BMAx_orderSystem.png";
import { keyframes } from '@emotion/react';
import DefaultLayout from "layouts/authentication/components/DefaultLayout/DefaultLayout";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const MaintenancePage = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'relative', // Ensures the box is relative for absolute positioning inside
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      }}
    >
      {/* Logo at the top */}
      <Box sx={{
          position: 'absolute', // Absolutely position the logo
          top: 50, // Adjust based on your preference
          width: '100%', // Ensures center alignment
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Link href="/home" sx={{ display: 'inline-flex', alignItems: 'center' }}>
          <img src={App_logo} alt="App Logo" style={{ width: "350px", height: "100%" }} />
        </Link>
      </Box>

    
      {/* Animated Gear Icon */}
      <Box sx={{ fontSize: 80, color: "#3498db", animation: `${rotate} 3s linear infinite` }}>
        <SettingsIcon fontSize="inherit" />
      </Box>

      {/* Title */}
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          marginTop: 2, // Increased top margin to adjust spacing from the logo
          marginBottom: 1,
        }}
      >
        Site is under maintenance
      </Typography>

      {/* Subtitle */}
      <Typography variant="subtitle1" sx={{ color: "#95a5a6" }}>
        We&apos;re working hard to improve the user experience. Stay tuned!
      </Typography>
    </Box>
  );
};

export default MaintenancePage;
