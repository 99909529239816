import React, { useState } from "react";
import { useEffect } from "react";
import PropTypes from "prop-types";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SoftTypography from "components/SoftTypography";
import InputAdornment from '@mui/material/InputAdornment';
import SoftButton from "components/SoftButton";
import CircularProgress from "@mui/material/CircularProgress"; // Importing CircularProgress for loader
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  FormControlLabel,
  TextField,
  Button,
  Select,
  MenuItem,
  Grid,
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle
} from "@mui/material";
import SoftBox from "components/SoftBox";

import { getVehicleTypes, getConcretePresets, updateOrder, deleteDelivery  } from "api/apiService"; // Import the new API

const EditProjectAccordion = ({ data, orderNumber, onSave }) => {
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [concretePresets, setConcretePresets] = useState([]);
  const [editableData, setEditableData] = useState(data);
  const [loading, setLoading] = useState(false);  // Loader state
  const [successMessage, setSuccessMessage] = useState("");  // Success message state
  const [openModal, setOpenModal] = useState(false);
  const [deliveryToDelete, setDeliveryToDelete] = useState(null);  // Store the delivery ID to delete
  const [expandedIndex, setExpandedIndex] = useState(null);
  console.log("editableData response:", editableData);
  console.log("concretePresets response:", concretePresets);

  // Trigger cancel button to open confirmation modal
  const handleCancelClick = async  (index) => {
    setDeliveryToDelete(index + 1);  // Set delivery ID to be deleted
    setOpenModal(true);  // Open confirmation modal
  };

  // Call API to delete the delivery on confirmation
  const handleConfirmDelete = async () => {
    setLoading(true); // Start loader
    setOpenModal(false);  // Close the modal
    try {
      const response = await deleteDelivery(orderNumber, deliveryToDelete);  // Pass orderId and deliveryId to the API
      console.log("deleting delivery response:", response);
      setLoading(false);
      if (response.status === 'success') {
        alert("Delivery successfully deleted!");
        
        setLoading(false); // Start loader
        window.location.reload();  // Optionally, reload the page after successful deletion
      }
    } catch (error) {
      setLoading(false); // Start loader
      console.error("Error deleting delivery:", error);
      alert("Error deleting delivery.");
    }
  };

  // Close the modal if user cancels the deletion
  const handleCancelDelete = () => {
    setOpenModal(false);  // Close the modal without deleting
  };


  useEffect(() => {
    const getVehicleData = async () => {
      const vehicleTypesData = await getVehicleTypes(); // Fetch vehicle types from API
      setVehicleTypes(vehicleTypesData.results || []); // Assuming the vehicle types are in 'results'
    };

    getVehicleData();
  }, []);

  useEffect(() => {
  const fetchConcreteData = async () => {
    const concreteData = await getConcretePresets(); // Fetch concrete presets
    console.log('Initial concrete presets:', concreteData.results);
    let presets = concreteData.results || [];

    // Iterate over editableData to find entries with concrete_id 10
    const itemsWithId10 = editableData.filter(item => item.concrete_id === 10);

    itemsWithId10.forEach(item => {
      const foundPreset = presets.find(preset => preset.id === '10');
      if (foundPreset) {
        // If found, modify the existing preset
        presets = presets.map(preset => {
          if (preset.id === '10') {
            console.log('Before modification:', preset);
            return {
              ...preset,
              id: '10',
              full_name: item.concrete_type  // Set full name from item
            };
          }
          return preset;
        });
      } else {
        // If not found, add a new preset based on item details
        const newPreset = {
          id: '10',
          name: '', // Add appropriate default name if needed
          full_name: item.concrete_type
        };
        presets.push(newPreset);
        console.log('Added new preset:', newPreset);
      }
    });

    setConcretePresets(presets);
    console.log('Updated concrete presets:', presets);
  };

  fetchConcreteData();
}, [editableData]); // React on editableData changes


  const formatDateForInput = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
    const handleAddDelivery = () => {
    const newDelivery = {
      isNew: true, // Flag for new delivery
      arrival: "",
      time: "",
      vehicle_id: "",
      display_volume: 1,
      concrete_id: "",
      total_price: 0,
      decline: "",
      steel_fiber: "",
      retardation: "",
      accelerator: "",
      pump_wash: "",
      extra_volume: "",
      add_hose: "",
    };
    const newEditableData = [...editableData, newDelivery];
    setEditableData(newEditableData);
    setExpandedIndex(newEditableData.length - 1); // Expand the newly added delivery
  };
  const handleToggleAccordion = (index) => {
    setExpandedIndex((prev) => (prev === index ? null : index)); // Toggle expanded state
  };

  const handleRemoveNewDelivery = (index) => {
    const updatedData = [...editableData];
    updatedData.splice(index, 1); // Remove the delivery at the given index
    setEditableData(updatedData);
  };

  const handleChangeDate = (index, dateValue) => {
    const updatedData = [...editableData];
    const date = new Date(dateValue);
    updatedData[index].date = Math.floor(date.getTime() / 1000);
    setEditableData(updatedData);
  };

  const handleVehicleTypeChange = (index, vehicleId) => {
    const updatedData = [...editableData];

    // Update the vehicle type
    updatedData[index].vehicle_id = vehicleId;

    // Find the selected vehicle's max load and set it as the maximum volume
    const selectedVehicle = vehicleTypes.find((vehicle) => vehicle.id === vehicleId);
    if (selectedVehicle) {
      updatedData[index].max_load = selectedVehicle.max_load;  // Store max load in the current item

      // If the volume is greater than max load, reset it to max load
      if (updatedData[index].display_volume > selectedVehicle.max_load) {
        updatedData[index].display_volume = selectedVehicle.max_load;
      }
    } else {
      updatedData[index].display_volume = 1; // Clear volume if no vehicle is selected
    }

    setEditableData(updatedData);  // Update the state
  };

  // Ensure the volume value is set correctly based on max_load on first render
  useEffect(() => {
    const updatedData = [...editableData];
    updatedData.forEach((item, index) => {
      const selectedVehicle = vehicleTypes.find((vehicle) => vehicle.id === item.vehicle_id);
      if (selectedVehicle) {
        item.max_load = selectedVehicle.max_load;
        // Ensure the volume doesn't exceed the max load
        if (item.display_volume > item.max_load) {
          item.display_volume = item.max_load;
        }
      }
    });
    setEditableData(updatedData);
  }, [vehicleTypes]);  // Trigger this effect when vehicleTypes are fetched or updated


  const handleChange = (index, field, value) => {
    const updatedData = [...editableData];
    updatedData[index][field] = value;
    setEditableData(updatedData);
  };

  const handleSave = async  (index) => {
    // Mapping concrete quality names to IDs
    setLoading(true); // Start loader
    const item = editableData[index]; // Get the data for the current index

    const hoseLength = item.hose_length;

    // Map selected vehicle type name to the vehicle ID using the vehicleTypes array
    const vehicle = vehicleTypes.find(v => v.id === item.vehicle_id);
    const vehicleTypeId = vehicle ? vehicle.id : 0;  // Default to 0 if not found

    // const concrete = concretePresets.find((c) => c.id === item.concrete_id);
    const concreteId = item.concrete_id ? item.concrete_id : 0; // Default to 0 if not found

    // Convert the date to UNIX timestamp (in seconds)
    const unixTimestamp = item.date; 

    // Prepare the data to be saved
    const savedData = {
      order_id: orderNumber, 
      deliveryId: index + 1,
      date: unixTimestamp, // Convert to readable date
      time: item.time,
      volume: parseFloat(item.display_volume),
      selectAreaLater: true,
      selectVehicleLater: true,
      vehicleType: vehicleTypeId, // Use the vehicle ID from the dropdown
      concreteIsCustom: item.concrete_type === "custom",
      customConcreteType: item.concrete_type === "custom" ? item.custom_concrete : null,
      concrete: concreteId,
      decline: item.decline === "checked",
      retardation: item.retardation === "checked",
      steelFiber: item.steel_fiber === "checked",
      accelerator: item.accelerator === "checked",
      pumpWash: item.pump_wash === "checked",
      pumploss: false, // Default value if not present
      extraVolume: item.extra_volume === "checked",
      addHose: item.add_hose === "checked",
      hoseLength: hoseLength, // Increment hoseLength if add_hose is checked
      price: 0,
      origianl_price: 0,
      isNew: undefined, // Remove the isNew flag
    };

    console.log("Saved Data:", savedData);
    // Call the onSave callback to pass the saved data
    // onSave(savedData);

    try {
      const response = await updateOrder(savedData);
      console.log("Order updated:", response);
      setLoading(false); // Stop loader

      if (response.status === 'success') {
        // Show success message in an alert
        alert("Successfully updated the order!");

        // Optionally, you can reload the page after 2 seconds to reflect changes
        setTimeout(() => {
          window.location.reload(); // Reload the page after success message
        }, 2000);
      }
    } catch (error) {
      console.error("Error updating order:", error);
      setLoading(false); // Stop loader

      // Show error message in an alert
      alert("Error updating the order.");
    }

  };


  return (
    <SoftBox mt={5}>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            zIndex: 1000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={100} color="info" />
        </div>
      )}
      <Dialog open={openModal} onClose={handleCancelDelete}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this delivery?
        </DialogContent>
        <DialogActions sx={{ margin: 1 }}>
          <SoftButton onClick={handleCancelDelete} variant="outlined" color="error">Cancel</SoftButton>
          <SoftButton onClick={handleConfirmDelete} variant="gradient" color="info">Confirm</SoftButton>
        </DialogActions>
      </Dialog>
      <SoftTypography variant="h2" mb={2} px={1}>
        Delivery Details
      </SoftTypography>
      {editableData.length > 0 ? (
        editableData.map((item, index) => (
          <Accordion
            key={index}
            expanded={expandedIndex === index} // Expand the accordion if the index matches
            onChange={() => handleToggleAccordion(index)} // Handle toggle
            sx={{
              borderRadius: "16px !important",
              "&::before": { content: "none" },
              mb: 2,
              py: 2,
              px: 1,
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <SoftTypography variant="h6">Delivery # {index + 1}</SoftTypography>
            </AccordionSummary>
            <AccordionDetails>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ padding: "0.75rem 0.45rem !important"}}>
                      <SoftTypography variant="caption" fontWeight="bold" > Arrival</SoftTypography>
                      <TextField
                        type="date"
                        value={formatDateForInput(item.date)}
                        onChange={(e) => handleChangeDate(index, e.target.value)}
                        fullWidth
                      />
                    </TableCell>

                    <TableCell sx={{ padding: "0.75rem 0.45rem !important"}}>
                      <SoftTypography variant="caption" fontWeight="bold"> Time</SoftTypography>
                      <TextField
                        type="time"
                        value={item.time}
                        onChange={(e) => handleChange(index, "time", e.target.value)}
                        fullWidth
                      />
                    </TableCell>
                    <TableCell sx={{ padding: "0.75rem 0.45rem !important"}}>
                      <SoftTypography variant="caption" fontWeight="bold">
                        Vehicle Type
                      </SoftTypography>
                      <Select
                        value={item.vehicle_id || ''} // Default to empty string if vehicle_id is undefined
                        onChange={(e) => handleVehicleTypeChange(index, e.target.value)}
                        fullWidth
                        displayEmpty
                      >
                        {/* Placeholder Option */}
                        <MenuItem value="" disabled>
                          Select Vehicle Type
                        </MenuItem>
                        {/* Vehicle Type Options */}
                        {vehicleTypes.map((vehicle) => (
                          <MenuItem key={vehicle.id} value={vehicle.id}>
                            {vehicle.name}
                          </MenuItem>
                        ))}
                      </Select>

                    </TableCell>
                    <TableCell style={{ width: "88px" }} sx={{ padding: "0.75rem 0.45rem !important"}}>
                      <SoftTypography variant="caption" fontWeight="bold">
                        Volume
                      </SoftTypography>
                      <TextField
                        type="number"
                        value={item.display_volume || 1}
                        onChange={(e) => {
                          const newVolume = parseFloat(e.target.value);
                          if (newVolume > 0 && newVolume <= item.max_load) {
                            handleChange(index, "display_volume", newVolume);
                          } else {
                            handleChange(index, "display_volume", item.max_load);
                          }
                        }}
                        style={{ width: "100px !important"}}  // Right padding to input area
                        inputProps={{
                          min: 1,
                          max: item.max_load,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end" style={{ marginRight: "-24px" }}>
                              <span style={{ display: "flex", alignItems: "center", paddingRight: "5px" }}>
                                {/* Optionally, you can place any additional elements here */}
                              </span>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </TableCell>


                    
                    <TableCell sx={{ padding: "0.75rem 0.45rem !important"}}>
                      <SoftTypography variant="caption" fontWeight="bold"> Concrete Quality</SoftTypography>
                      <Select
                        value={item.concrete_id || ""} // Default to empty string if concrete_id is undefined
                        onChange={(e) => handleChange(index, "concrete_id", e.target.value)}
                        fullWidth
                        displayEmpty
                      >
                        {/* Placeholder Option */}
                        <MenuItem value="" disabled>
                          Select Concrete Type
                        </MenuItem>
                        {/* Concrete Options */}
                        {concretePresets.map((concrete) => (
                          <MenuItem key={concrete.id} value={concrete.id}>
                            {concrete.full_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell sx={{ padding: "0.75rem 0.45rem !important"}}>
                      <SoftTypography variant="caption" fontWeight="bold"> Total Price</SoftTypography>
                      <TextField
                        value={item.total_price || 0} // Default to 0 if undefined
                        onChange={(e) => handleChange(index, "total_price", e.target.value)}
                        InputProps={{
                          readOnly: true, // Makes the field non-editable (disabled)
                        }}
                        sx={{
                          width: "130px", // Adjust width as needed
                        }}
                      />
                    </TableCell>

                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={6}>
                      <SoftTypography fontSize=".8rem" variant="subtitle" color="secondary" sx={{ mt: 2, mb: 1 }}>
                        Modeling Options
                      </SoftTypography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={item.decline === "checked"}
                                onChange={(e) => handleChange(index, "decline", e.target.checked ? "checked" : "")}
                              />
                            }
                            label="Cast with drop"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={item.steel_fiber === "checked"}
                                onChange={(e) => handleChange(index, "steel_fiber", e.target.checked ? "checked" : "")}
                              />
                            }
                            label="Add steel fiber"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={item.retardation === "checked"}
                                onChange={(e) => handleChange(index, "retardation", e.target.checked ? "checked" : "")}
                              />
                            }
                            label="Delay"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={item.accelerator === "checked"}
                                onChange={(e) => handleChange(index, "accelerator", e.target.checked ? "checked" : "")}
                              />
                            }
                            label="Accelerator"
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={6}>
                      <SoftTypography fontSize=".8rem" variant="subtitle" color="secondary" sx={{ mt: 2, mb: 1 }}>
                        If Pump Mixer
                      </SoftTypography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={item.pump_wash === "checked"}
                                onChange={(e) => handleChange(index, "pump_wash", e.target.checked ? "checked" : "")}
                              />
                            }
                            label="Add Pump waste (0.5m³)"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={item.extra_volume === "checked"}
                                onChange={(e) => handleChange(index, "extra_volume", e.target.checked ? "checked" : "")}
                              />
                            }
                            label="Wash on mixer"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={item.add_hose === "checked"}
                                onChange={(e) => handleChange(index, "add_hose", e.target.checked ? "checked" : "")}
                              />
                            }
                            label="Snakes"
                          />
                        </Grid>
                        {item.add_hose === "checked" && (
                          <Grid item xs={3}>
                            <TextField
                              label="Hose Length"
                              type="number"
                              value={item.hose_length || 0 }
                              onChange={(e) => handleChange(index, "hose_length", e.target.value)}
                              variant="outlined"
                              InputLabelProps={{
                                style: { color: '#344767' }, // Always black color for the label
                              }}
                              InputProps={{
                                style: { color: '#344767' }, // Ensures the input text is also always black
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {/* Optionally add an icon or text here if needed */}
                                  </InputAdornment>
                                )
                              }}
                              onFocus={(e) => e.target.style.color = '#344767'}
                              onBlur={(e) => e.target.style.color = '#344767'}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>

            
              <SoftBox mt={2}>
                <SoftButton variant="gradient" color="info" onClick={() => handleSave(index)} sx={{ mt: 2 }}>
                  Save
                </SoftButton>

                {item.isNew ? (
                  <SoftButton
                    variant="outlined"
                    color="error"
                    onClick={() => handleRemoveNewDelivery(index)}
                    sx={{ mt: 2, ml: 2 }}
                  >
                    Remove
                  </SoftButton>
                ) : (
                  <SoftButton
                    variant="outlined"
                    color="error"
                    onClick={() => handleCancelClick(index)}
                    sx={{ mt: 2, ml: 2 }}
                  >
                    Delete
                  </SoftButton>
                )}
              </SoftBox>


            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <SoftTypography>No deliveries available</SoftTypography>  // Fallback when there are no deliveries
      )}
      <SoftButton variant="gradient" color="info" onClick={handleAddDelivery} sx={{ mt: 2 }}>
        Add Delivery
      </SoftButton>
    </SoftBox>
  );
};

EditProjectAccordion.propTypes = {
  data: PropTypes.array.isRequired,
  orderNumber: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default EditProjectAccordion;
