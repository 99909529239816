/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/ 

import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box } from "@mui/material";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
// Soft UI Dashboard React examples
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import VendorInfoCard from "layouts/dashboard/components/ForProjects/OrderDetails/Cards/VendorInfoCard";
import Badge from "@mui/material/Badge";


//mui icons
import FireTruckIcon from "@mui/icons-material/FireTruck";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import CloudIcon from "@mui/icons-material/Cloud";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TitleIcon from "@mui/icons-material/Title";
import DescriptionIcon from "@mui/icons-material/Description";
import BusinessIcon from "@mui/icons-material/Business";
import DistanceIcon from "@mui/icons-material/Explore"
import WorkIcon from "@mui/icons-material/Work";
import EventIcon from "@mui/icons-material/Event";
import VerifiedIcon from "@mui/icons-material/Verified"; // Status Icon

//pictogrammers icons

// Soft UI Dashboard React icons
import Cube from "examples/Icons/Cube";
import Document from "examples/Icons/Document";
import Settings from "examples/Icons/Settings";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import { Modal, FormControl, InputLabel, Select, } from "@mui/material";

// Soft UI Dashboard React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import burceMars from "assets/images/bruce-mars.jpg";
import home from "assets/images/home.jpg";
import homeimage from "assets/images/h2.jpg";
import curved0 from "assets/images/curved-images/curved0.jpg";
import MyGoogleMap from "layouts/dashboard/components/ForProjects/OrderDetails/GoogleMap";
import { object } from "prop-types";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ProjectAccordion from "layouts/profile/components/ProjectAccordion/ProjectAccordion";
import borders from "assets/theme/base/borders";
import boxShadow from "assets/theme/functions/boxShadow";
import typography from "assets/theme/base/typography";
import SoftButton from "components/SoftButton";
import EditIcon from "@mui/icons-material/Edit";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import CloseIcon from "@mui/icons-material/Close";
import { getOrderById, approveProject, changeRequestByVendor, cancelRequestByVendor, fetchStatusList, fetchVendorList, changeVendor, changeOrderStatus } from "api/apiService";
import CircularProgress from "@mui/material/CircularProgress"; // Importing CircularProgress for loader

import { NavLink } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom";

function OrderDetail() {
  const location = useLocation();
  const { orderId } = useParams();
  // const orderId = location.state?.orderId;
  console.log("orderDetail page-----", orderId,);
  const [orderData, setOrderData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
   const navigate = useNavigate(); // Use the navigate hook
  
  const { size, fontWeightBold } = typography;
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [changeDate, setChangeDate] = useState('');
  const [changeTime, setChangeTime] = useState('');
  const [changeDetail, setChangeDetail] = useState('');
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [orderIdToCancel, setOrderIdToCancel] = useState(null); // Store orderId for cancellation

  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [statusList, setStatusList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');

  const [openVendorModal, setOpenVendorModal] = useState(false);
  const [vendorList, setVendorList] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState('');

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  // const dataSet = { weather_temprature: 4, weather_icon: "url", address: "address" };
  const isAlreadyApproved = orderData?.order_meta?._project_approved?.[0] === 'yes'; 
  const isDateApproved = orderData?.order_meta?._approved_request?.[0] === 'yes'; 
  const isDateDeny = orderData?.order_meta?._approved_request?.[0] === 'no'; 
  
  const createdBy = orderData?.order_meta?._customer_user?.[0];

  const role = localStorage.getItem("role");

  const loggedInUserId = JSON.parse(localStorage.getItem("user"))?.id;  // Get the logged-in user's ID

  // Check if the logged-in vendor is trying to approve their own order
  const isVendorOwnOrder = role === "vendor" && parseInt(createdBy) === parseInt(loggedInUserId);

 

  const fetchOrderDetails = async () => {
    const data = await getOrderById({ order_id: orderId });

    if (data.status) {
      setOrderData(data.data);
      setIsLoading(false);

      console.log("set data", orderData, data.data);
    }

    console.log("fetch order by id: ", data);
  };

  const fetchVendors = async () => {
    try {
      const response = await fetchVendorList();
      console.log(" fetching Vendors", response);
      if (response.status) {
        setVendorList(response.vendor_list);
      }
    } catch (error) {
      console.error("Error fetching Vendores", error);
    }
  };

  const handleVendorChange = (event) => {
    setSelectedVendor(event.target.value);
  };

  const handleConfirmVendorChange = async () => {
    if (!selectedVendor) {
      alert("Please select a Vendor");
      return;
    }
    setIsLoading(true);
    try {
      const orderAddress = orderData?.address;
      // API call to change status
      const response = await changeVendor(orderId, selectedVendor, orderAddress); // Change this to the actual API call
      console.log("changeVendor response", response);
      if (response.status) {
        setOpenVendorModal(false);
        alert("Vendor changed successfully!");
        setIsLoading(false);
        window.location.reload();
      } else {
        alert("Failed to vendor change. Please try again.");
      }
    } catch (error) {
      console.error("Error changing vendor", error);
      alert("An error occurred while changing the vendor.");
      setIsLoading(false);
    }
  };

  const handleOpenVendorModal = () => {
    setOpenVendorModal(true);
  };

  const handleCloseVendorModal = () => {
    setOpenVendorModal(false);
  };


  const fetchStatuses = async () => {
    try {
      const response = await fetchStatusList();
      console.log(" fetching statuses", response);
      if (response.status) {
        setStatusList(response.status_list);
      }
    } catch (error) {
      console.error("Error fetching statuses", error);
    }
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleConfirmStatusChange = async () => {
    if (!selectedStatus) {
      alert("Please select a status");
      return;
    }
    setIsLoading(true);
    
    try {
      // API call to change status
      const response = await changeOrderStatus(orderId, selectedStatus); // Change this to the actual API call
      console.log("selectedStatus response", response);
      if (response.status) {
        setOpenStatusModal(false);
        alert("Status changed successfully!");
        setIsLoading(false);
        window.location.reload();
      } else {
        alert("Failed to change status. Please try again.");
      }
    } catch (error) {
      console.error("Error changing status", error);
      alert("An error occurred while changing the status.");
      setIsLoading(false);
    }
  };

  const handleOpenStatusModal = () => {
    setOpenStatusModal(true);
  };

  const handleCloseStatusModal = () => {
    setOpenStatusModal(false);
  };



  // Handle Approval
  const handleApproval = async (orderId) => {
    setIsLoading(true);
    try {
      const vendor_id = localStorage.getItem("vendor_id"); // Get vendor's ID from localStorage
      
      // Call the API to approve the request
      const response = await approveProject(orderId, vendor_id);
      console.log("approving response:", response);
      setIsLoading(false);
      if (response.status) {
        // If the approval was successful, update the status text or any other state
        alert("Order approved successfully!");
        // Optionally reload the page or fetch updated data

        window.location.reload();
      } else {
        alert("Failed to approve the order. Please try again.");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error approving order:", error);
      alert("An error occurred while approving the order.");
    }
  };

  const openCancelDialog = (orderId) => {
    setOrderIdToCancel(orderId); // Set the orderId to be canceled
    setCancelDialogOpen(true); // Open the cancel confirmation dialog
  };

  // Function to close the cancel confirmation dialog
  const closeCancelDialog = () => {
      setCancelDialogOpen(false); // Close the dialog
  };

  // Function to confirm and send the cancel request
  const confirmCancelRequest = async () => {
    
    setIsLoading(true); // Show loading state while the request is being processed
    try {
        // Make the API call to cancel the request
        const response = await cancelRequestByVendor(orderIdToCancel); // Pass the orderId
        console.error("response sending cancel request:", response);
        // Close the dialog once the request is sent
        setCancelDialogOpen(false);
        setIsLoading(false);

        if (response.status) {
            alert("Order cancel successfully!");
            // Optionally reload or update the state
            navigate("/dashboard");
        } else {
            alert("Failed to order cancel. Please try again.");
        }
    } catch (error) {
        setIsLoading(false);
        console.error("Failed to order cancel. Please try again.:", error);
        alert("An error occurred while sending the cancel request.");
    }
  };

  // Handle Change Request

  const handleChangeRequest = async (orderId) => {
    if (!changeDate || !changeTime || !changeDetail) {
      alert("Please fill in all fields");
      return;
    }
    setIsLoading(true);
    try {
      // Call the API to send the change request
      const response = await changeRequestByVendor(orderId, changeDate, changeTime, changeDetail);
      setOpen(false);
       setIsLoading(false);
      if (response.status) {
        alert("Change request sent successfully!");
        // window.location.reload();
      } else {
        alert("Failed to send change request. Please try again.");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error sending change request:", error);
      alert("An error occurred while sending the change request.");
    }
  };

  
  const openChangeRequestModal = () => {
    setOpen(true); // Opens the modal
  };

  // Close Change Request Modal
  const closeChangeRequestModal = () => {
    setOpen(false); 
  };




  useEffect(() => {
    fetchOrderDetails();
    fetchStatuses();
    fetchVendors();
  }, []);

  return (
    <DashboardLayout>
      <Box>
        {isLoading && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(255, 255, 255, 0.3)",
              zIndex: 1000,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={100} color="info" />
          </div>
        )}


        {!isLoading && (
          <>
            {/* <SoftBox mt={4}>
                <Box sx={{ display: "flex", gap: "10px", justifyContent: "end" }}>
                  <Box component={NavLink} to={`/projects/order-details/${orderId}`}>
                    {role === "admin" && (
                      <SoftButton
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        variant="outlined"
                        color="info"
                        size="small"
                      >
                        <EditIcon fontSize="medium" />
                        <span style={{ padding: "0 6px" }}>Edit</span>
                      </SoftButton>
                    )}
                  </Box>
                  <Box component={NavLink} to={`/projects/order-details/${orderId}`}>
                    {["admin", "vendor"].includes(role) && (
                      <SoftButton
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        variant="outlined"
                        color="info"
                        size="small"
                        onClick={handleChangeRequest}
                      >
                        <AutorenewIcon fontSize="medium" />
                        <span style={{ padding: "0 6px" }}>Change Request</span>
                      </SoftButton>
                    )}
                  </Box>
                  <Box component={NavLink} to={`/projects/order-details/${orderId}`}>
                    {["admin", "vendor", "customer"].includes(role) && (
                      <SoftButton
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        variant="outlined"
                        color="info"
                        size="small"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyItems: "center",
                          alignItems: "center",
                        }}
                      >
                        <CloseIcon fontSize="medium" />
                        <span style={{ padding: "0 6px" }}>Cancel Request</span>
                      </SoftButton>
                    )}
                  </Box>

                   <SoftButton variant="outlined" color="secondary" size="small">
                <EditIcon />
                <span style={{ padding: "0 6px" }}>Edit</span>
              </SoftButton> 
                </Box>
              </SoftBox>
            */}

            <SoftBox position="relative" mt={4}>
              {/* <DashboardNavbar absolute dark /> */}
            
              <SoftBox
                display="flex"
                alignItems="center"
                position="relative"
                minHeight="18.75rem"
                borderRadius="xl"
                sx={{
                  // backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
                  //   `${linearGradient(
                  //     rgba(gradients.info.main, 0.6),
                  //     rgba(gradients.info.state, 0.6)
                  //   )},

                  //   url(${curved0})`,
                  backgroundSize: "cover",
                  backgroundPosition: "50%",
                  overflow: "hidden",
                  boxShadow:
                    " rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                }}
              >
                <MyGoogleMap lng={+orderData?.lng} lat={+orderData?.lat} />
              </SoftBox>
              <Card
                sx={{
                  width: "93%",

                  backdropFilter: `saturate(200%) blur(30px)`,
                  background: "rgba(255, 255, 255, 0.6)",

                  boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
                  position: "relative",
                  mt: -8,
                  mx: "auto",
                  py: 2,
                  px: 2,
                }}
              >
                <Grid container spacing={3} alignItems="center">
                  <Grid item>
                    <SoftAvatar
                      src={homeimage}
                      alt="profile-image"
                      variant="rounded"
                      size="xl"
                      shadow="sm"
                    />
                  </Grid>
                  <Grid item>
                    <SoftBox height="100%" mt={0.5} lineHeight={1}>
                      <SoftTypography variant="h5" fontWeight="medium" color="rgba(0, 0, 0, 0.87)">
                        Delivery Address
                      </SoftTypography>
                      <SoftTypography variant="button" fontWeight="medium" color="rgba(0, 0, 0, 0.87)">
                        {orderData.address}
                      </SoftTypography>
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={5.5} sx={{ ml: "auto" }}>
                    <AppBar position="static">
                      <Box sx={{ display: "flex",
                        flexWrap: "wrap",
                        gap: "20px", }}>
                        <SoftBox
                          sx={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                            backgroundColor: "#fff",
                            padding: "8px 12px",
                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                            borderRadius: "5px",
                            p: 1,

                            // minWidth: { xs: "auto", sm: "200px" }, width: {
                            //   xs: "auto", sm: "200px"
                            // }
                          }}
                        >
                          <FireTruckIcon sx={{ fontSize: "28px", color: "#FFC312" }} />
                          <SoftTypography color="rgba(0, 0, 0, 0.87)"
                          fontWeight="medium"
                          sx={{ fontSize: "13px" }}>
                            {orderData?.total_volume} mm
                          </SoftTypography>
                        </SoftBox>

                        <SoftBox
                          sx={{
                            display: "flex",
                            gap: "12px",
                            alignItems: "center",
                            backgroundColor: "#fff",
                            padding: "8px 12px",
                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                            borderRadius: "5px",
                            p: 1,

                            // minWidth: { xs: "auto", sm: "200px" }, width: {
                            //   xs: "auto", sm: "200px"
                            // }
                          }}
                        >
                          <EventIcon sx={{ fontSize: "28px", color: "grey" }} />
                          <SoftTypography 
                            color="rgba(0, 0, 0, 0.87)"
                            fontWeight="medium"
                            sx={{ fontSize: "13px" 
                            }}
                          >
                            {role === "customer" && 
                              (orderData?.order_status?.status_text === "Quality assurance" || 
                               orderData?.order_status?.status_text === "Awaiting Payment" || 
                               orderData?.order_status?.status_text === "Behandler" ||
                               orderData?.order_status?.status_text === "Awaiting Vendor" ||
                               orderData?.order_status?.status_text === "On-hold")
                                ? "Date Being Planned"
                                : orderData?.delivery_date[0]?.date
                            }
                          </SoftTypography>
                        </SoftBox>

                        <SoftBox
                          sx={{
                            display: "flex",
                            gap: "12px",
                            alignItems: "center",
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                            padding: "8px 12px",
                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                            p: 1,
                            // minWidth: { xs: "auto", sm: "130px" }, width: {
                            //   xs: "auto", sm: "130px"
                            // }
                          }}
                        >
                          <CloudIcon sx={{ fontSize: "28px", color: "#4B7BEC" }} />
                          <SoftTypography color="rgba(0, 0, 0, 0.87)"
                            fontWeight="medium"
                            sx={{ fontSize: "13px" }}>
                             Weather {orderData?.weather_temprature}
                          </SoftTypography>
                        </SoftBox>

                        {/* <Tab disabled sx={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", fontSize: '1rem' }} label={"1300 mm Levernce"} icon={<FireTruckIcon sx={{ color: "info.main", fontSize: "40px" }} />} ></Tab>
                    <Tab disabled sx={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", fontSize: '0.7rem' }} label="Delivery Date 11/2/2024" icon={<LocalShippingIcon sx={{ color: "info.main" }} />} />
                    <Tab disabled sx={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", fontSize: '0.7rem' }} label="Weather 5 C" icon={<CloudIcon sx={{ color: "info.main" ,fontSize: "40px"}} />} />  */}
                      </Box>
                    </AppBar>
                  </Grid>
                </Grid>
              </Card>
            </SoftBox>
            {["admin"].includes(role) && (
              <SoftBox mt={4} mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12} xl={12}>
                    <Card
                      sx={{
                        borderRadius: "12px",
                        padding: 3,
                        background: "linear-gradient(135deg,#fff, #fff)", // Gradient background
                        color: "white",
                        boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
                        flexDirection: "column",
                        height: "100%", // Force equal height
                      }}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6} xl={6}>
                          <SoftButton
                            variant="gradient"
                            color="success"
                            fullWidth
                            size="small"
                            onClick={handleOpenStatusModal}
                            sx={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              padding: "8px",
                            }}
                          >
                            Change Status
                          </SoftButton>
                        </Grid>
                    
                        
                          
                          <Grid item xs={12} md={6} xl={6}>
                            <SoftButton
                              variant="gradient"
                              color="info"
                              fullWidth
                              size="small"
                              onClick={handleOpenVendorModal}
                              sx={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                padding: "8px",
                              }}
                            >
                              Change Vendor
                            </SoftButton>
                          </Grid>
                      
                      
                          {/*
                            <Grid item xs={12} md={4} xl={4}>
                              <SoftButton
                                variant="gradient"
                                color="error"
                                fullWidth
                                size="small"
                                sx={{
                                  fontWeight: "bold",
                                  fontSize: "14px",
                                  padding: "8px",
                                }}
                              >
                                Cancel Order
                              </SoftButton>
                            </Grid>
                          */}

                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </SoftBox>
            )}

            {["customer"].includes(role) && (
              <SoftBox mt={4} mb={3}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} xl={12}>
                    <Card
                      sx={{
                        borderRadius: "12px",
                        padding: 3,
                        background: "linear-gradient(135deg,#fff, #fff)",
                        color: "white",
                        boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
                        flexDirection: "column",
                        height: "100%",
                      }}
                    >
                      <Grid container spacing={1}>
                        {/* Status */}
                        <Grid item xs={12} md={3} xl={3}>
                          <SoftTypography
                            variant="h4"
                            fontWeight="bold"
                            sx={{
                              fontSize: "22px",
                              fontWeight: "medium",
                              padding: "5px 15px",
                              color: "rgba(0, 0, 0, 0.70)",
                              borderRadius: "20px",
                              textAlign: "center",
                              display: "inline-block",
                              position: "relative",
                              "&::before": {
                                content: '"-> "',
                                position: "absolute",
                                left: "-10px",
                                color: "rgba(0, 0, 0, 0.70)",
                                fontWeight: "bold",
                              },
                              "&::after": {
                                content: '" <-"',
                                position: "absolute",
                                right: "-10px",
                                color: "rgba(0, 0, 0, 0.70)",
                                fontWeight: "bold",
                              },
                            }}
                          >
                            {orderData?.order_status?.status_text}
                          </SoftTypography>
                        </Grid>

                        {/* Amount */}
                        <Grid item xs={12} md={2} xl={2}>
                          <SoftTypography
                            variant="h4"
                            fontWeight="bold"
                            sx={{
                              fontSize: "22px",
                              fontWeight: "medium",
                              padding: "5px 15px",
                              color: "rgba(0, 0, 0, 0.70)",
                              borderRadius: "20px",
                              textAlign: "center",
                              display: "inline-block",
                              position: "relative",
                            }}
                          >
                            {orderData?.order_status?.status_value}
                          </SoftTypography>
                        </Grid>

                        {/* VAT Information */}
                        <Grid item xs={12} md={4} xl={4}>
                          <SoftTypography
                            variant="h4"
                            fontWeight="bold"
                            sx={{
                              fontSize: "16px",
                              fontWeight: "normal",
                              padding: "5px 15px",
                              color: "rgba(0, 0, 0, 0.70)",
                              borderRadius: "20px",
                              textAlign: "center",
                              display: "inline-block",
                              position: "relative",
                            }}
                          >
                            {orderData?.order_status?.status_vat}
                          </SoftTypography>
                        </Grid>

                        {/* Action Button */}
                        <Grid item xs={12} md={3} xl={3}>

                          {orderData?.order_status?.status_text === "Awaiting Payment" && (
                            <a
                              href={orderData?.order_status?.status_pay_url}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ textDecoration: "none" }}
                            >
                              <SoftButton
                                variant="gradient"
                                color="info"
                                fullWidth
                                size="small"
                                sx={{
                                  fontWeight: "bold",
                                  fontSize: "14px",
                                  padding: "8px",
                                }}
                              >
                                {orderData?.order_status?.status_pay_text}
                                
                              </SoftButton>
                            </a>
                          )}

                          {orderData?.order_status?.status_text === "Avventer leveranse" && (
                            <SoftButton
                              variant="gradient"
                              color="success"
                              fullWidth
                              size="small"
                              sx={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                padding: "8px",
                              }}
                            >
                              {orderData?.order_status?.status_pay_text}
                            </SoftButton>
                          )}

                          {orderData?.order_status?.status_text === "Quality assurance" && (
                            <SoftButton
                              variant="outlined"
                              color="dark"
                              fullWidth
                              size="small"
                              sx={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                padding: "8px",
                              }}
                            >
                              {orderData?.order_status?.status_pay_text}
                            </SoftButton>
                          )}

                          {orderData?.order_status?.status_text === "Cancelled" && (
                            <SoftButton
                              variant="outlined"
                              color="error"
                              fullWidth
                              size="small"
                              sx={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                padding: "8px",
                              }}
                            >
                              {orderData?.order_status?.status_pay_text}
                            </SoftButton>
                          )}

                          {orderData?.order_status?.status_text === "Failed" && (
                            <SoftButton
                              variant="outlined"
                              color="error"
                              fullWidth
                              size="small"
                              sx={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                padding: "8px",
                              }}
                            >
                              {orderData?.order_status?.status_pay_text}
                            </SoftButton>
                          )}

                          {orderData?.order_status?.status_text === "Draft" && (
                            <SoftButton
                              variant="outlined"
                              color="warning"
                              fullWidth
                              size="small"
                              sx={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                padding: "8px",
                              }}
                            >
                              {orderData?.order_status?.status_pay_text}
                            </SoftButton>
                          )}


                          {orderData?.order_status?.status_text === "On-hold" && (
                            <SoftButton
                              variant="outlined"
                              color="info"
                              fullWidth
                              size="small"
                              sx={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                padding: "8px",
                              }}
                            >
                              {orderData?.order_status?.status_pay_text}
                            </SoftButton>
                          )}

                          {["Awaiting Vendor", "Behandler", "Delivered"].includes(orderData?.order_status?.status_text) &&
                            orderData?.order_status?.status_invoice && (
                            
                              <a
                                href={orderData?.order_status?.status_invoice}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: "none" }}
                              >
                                <SoftButton
                                  variant="gradient"
                                  color="info"
                                  fullWidth
                                  size="small"
                                  sx={{
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    padding: "8px",
                                  }}
                                >
                                  Receipt
                                </SoftButton>
                              </a>
                            
                          )}

                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </SoftBox>
            )}


            <Dialog open={openStatusModal} onClose={handleCloseStatusModal}>
              <DialogTitle>Select Status</DialogTitle>
              <DialogContent>
                {/* Add a label above the dropdown */}
                
               
                <Select
                  value={selectedStatus || ''} // Default to empty string if vehicle_id is undefined
                  onChange={handleStatusChange}
                  fullWidth
                  displayEmpty 
                  
                >
                  {/* Placeholder Option */}
                  <MenuItem value="" disabled>
                    Select Status Type
                  </MenuItem>
                  {/* Vehicle Type Options */}
                  {statusList && statusList.length > 0 ? (
                    statusList.map((status) => (
                      <MenuItem key={status.value} value={status.value}>
                        {status.label}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">No statuses available</MenuItem>  // Fallback when no status is available
                  )}
                </Select>
            
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseStatusModal} color="primary">
                  Close
                </Button>
                <Button onClick={handleConfirmStatusChange} color="primary">
                  Save
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog open={openVendorModal} onClose={handleCloseVendorModal}>
              <DialogTitle>Select Vendor</DialogTitle>
              <DialogContent>
                {/* Add a label above the dropdown */}
                
               
                <Select
                  value={selectedVendor || ''} 
                  onChange={handleVendorChange}
                  fullWidth
                  displayEmpty 
                  
                >
                  {/* Placeholder Option */}
                  <MenuItem value="" disabled>
                    Select Vendor Type
                  </MenuItem>
                  {/* Vehicle Type Options */}
                  {vendorList && vendorList.length > 0 ? (
                    vendorList.map((vendor) => (
                      <MenuItem key={vendor.id} value={vendor.id}>
                        {vendor.title}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">No vendors available</MenuItem>  // Fallback when no vendor is available
                  )}
                </Select>
            
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseVendorModal} color="primary">
                  Close
                </Button>
                <Button onClick={handleConfirmVendorChange} color="primary">
                  Save
                </Button>
              </DialogActions>
            </Dialog>



            

            <SoftBox mt={4} mb={3}>
              <Grid container spacing={3}>
                
                <Grid item xs={12} md={12} xl={4}>
                  <Card
                    sx={{
                      borderRadius: "12px",
                      padding: 3,
                      background: "linear-gradient(135deg,#bbd2c5, #536976)", // Gradient background
                      color: "white",
                      boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
                      flexDirection: "column",
                      height: "100%", // Force equal height
                    }}
                  >
                    {/* Title */}
                    <SoftTypography
                      variant="h5"
                      fontWeight="bold"
                      mb={3}
                      color="white"
                      textAlign="left"
                    >
                      Customer Information
                    </SoftTypography>

                    {/* Content */}
                    <Grid container spacing={1} alignItems="center">
                      {/* Name */}
                      <Grid item xs={2} display="flex" justifyContent="center">
                        <AccountCircleIcon sx={{ fontSize: 24, color: "#fff" }} />
                      </Grid>
                      <Grid item xs={10}>
                        <SoftTypography
                          variant="body2"
                          fontWeight="medium"
                          sx={{ fontSize: "14px", color: "#fff" }}
                        >
                          {orderData?.fullname}
                        </SoftTypography>
                      </Grid>

                      {/* Email */}
                      <Grid item xs={2} display="flex" justifyContent="center">
                        <EmailIcon sx={{ fontSize: 24, color: "#fff" }} />
                      </Grid>
                      <Grid item xs={10}>
                        <SoftTypography
                          variant="body2"
                          fontWeight="medium"
                          sx={{ fontSize: "14px", color: "#fff" }}
                        >
                          {orderData?.vendor_email}
                        </SoftTypography>
                      </Grid>

                      {/* Address */}
                      <Grid item xs={2} display="flex" justifyContent="center">
                        <LocationOnIcon sx={{ fontSize: 24, color: "#fff" }} />
                      </Grid>
                      <Grid item xs={10}>
                        <SoftTypography
                          variant="body2"
                          fontWeight="medium"
                          sx={{ fontSize: "14px", color: "#fff" }}
                        >
                          {orderData?.address}
                        </SoftTypography>
                      </Grid>
                      
                    </Grid>
                  </Card>
                </Grid>
                {/* 2nd Box: Prosecutor Information */}
                {["admin", "customer"].includes(role) && (
                  <Grid item xs={12} md={12} xl={4}>
                    <Card
                      sx={{
                        borderRadius: "12px",
                        padding: 3,
                        background: "linear-gradient(135deg, #4cb8c4, #3cd3ad)", // Gradient Background
                        color: "white",
                        boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
                        flexDirection: "column",
                        height: "100%", // Force equal height
                      }}
                    >
                      {/* Title */}
                      <SoftTypography
                        variant="h5"
                        fontWeight="bold"
                        mb={3}
                        color="white"
                        textAlign="left"
                      >
                        Prosecutor Information
                      </SoftTypography>

                      {/* Content */}
                      <Grid container spacing={1} alignItems="center">
                        {/* Name */}
                        <Grid item xs={2} display="flex" justifyContent="center">
                          <AccountCircleIcon sx={{ fontSize: 24, color: "#fff" }} />
                        </Grid>
                        <Grid item xs={10}>
                          <SoftTypography
                            variant="body2"
                            fontWeight="medium"
                            sx={{ fontSize: "14px", color: "#fff" }}
                          >
                            {orderData?.vendor_title}
                          </SoftTypography>
                        </Grid>

                        {/* Email */}
                        <Grid item xs={2} display="flex" justifyContent="center">
                          <EmailIcon sx={{ fontSize: 24, color: "#fff" }} />
                        </Grid>
                        <Grid item xs={10}>
                          <SoftTypography
                            variant="body2"
                            fontWeight="medium"
                            sx={{ fontSize: "14px", color: "#fff" }}
                          >
                            {orderData?.vendor_email}
                          </SoftTypography>
                        </Grid>

                        {/* Location */}
                        <Grid item xs={2} display="flex" justifyContent="center">
                          <LocationOnIcon sx={{ fontSize: 24, color: "#fff" }} />
                        </Grid>
                        <Grid item xs={10}>
                          <SoftTypography
                            variant="body2"
                            fontWeight="medium"
                            sx={{ fontSize: "14px", color: "#fff" }}
                          >
                            {orderData?.vendor_address}
                          </SoftTypography>
                        </Grid>

                        {/* Distance */}
                        <Grid item xs={2} display="flex" justifyContent="center">
                          <DistanceIcon sx={{ fontSize: 24, color: "#fff" }} />
                        </Grid>
                        <Grid item xs={10}>
                          <SoftTypography
                            variant="body2"
                            fontWeight="medium"
                            sx={{ fontSize: "14px", color: "#fff" }}
                          >
                            {orderData?.distance} km
                          </SoftTypography>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                )}
                {/* 3rd Box: Project Details */}
                <Grid item xs={12} md={12} xl={4}>
                  <Card
                    sx={{
                      borderRadius: "12px",
                      padding: 3,
                      background: "linear-gradient(135deg, #B0C4DE, #6A85B6)", // Gradient Background
                      color: "white",
                      boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
                      flexDirection: "column",
                      height: "100%", // Force equal height
                    }}
                  >
                    {/* Title */}
                    <SoftTypography
                      variant="h5"
                      fontWeight="bold"
                      mb={3}
                      color="white"
                      textAlign="left"
                    >
                      Project Details
                    </SoftTypography>

                    {/* Content */}
                    <Grid container spacing={1} alignItems="center">
                      {/* Order Title */}
                      <Grid item xs={2} display="flex" justifyContent="center">
                        <WorkIcon sx={{ fontSize: 24, color: "#fff" }} />
                      </Grid>
                      <Grid item xs={10}>
                        <SoftTypography
                          variant="body2"
                          fontWeight="medium"
                          sx={{ fontSize: "14px", color: "#fff" }}
                        >
                          {orderData?.order_title}
                        </SoftTypography>
                      </Grid>

                      {/* Created Date */}
                      <Grid item xs={2} display="flex" justifyContent="center">
                        <EventIcon sx={{ fontSize: 24, color: "#fff" }} />
                      </Grid>
                      <Grid item xs={10}>
                        <SoftTypography
                          variant="body2"
                          fontWeight="medium"
                          sx={{ fontSize: "14px", color: "#fff" }}
                        >
                          {orderData?.created_date?.date.split(" ")[0]}
                        </SoftTypography>
                      </Grid>

                      {/* Status */}
                      <Grid item xs={2} display="flex" justifyContent="center">
                        <VerifiedIcon sx={{ fontSize: 20, color: "#fff" }} />
                      </Grid>
                      <Grid item xs={10} display="flex" alignItems="center">
                        <SoftTypography
                          variant="body2"
                          fontWeight="medium"
                          sx={{
                            fontSize: "12px",            
                            backgroundColor: "#4CAF50",  
                            color: "#fff",             
                            fontWeight: "medium",
                            padding: "2px 8px",          
                            borderRadius: "10px",        
                            textAlign: "center",
                            display: "inline-block",  
                          }}
                        >
                          {orderData?.order_status?.status_text}
                        </SoftTypography>
                      </Grid>

                      {/* Description */}
                      <Grid item xs={2} display="flex" justifyContent="center">
                        <DescriptionIcon sx={{ fontSize: 24, color: "#fff" }} />
                      </Grid>
                      <Grid item xs={10}>
                        <SoftTypography
                          variant="body2"
                          fontWeight="medium"
                          sx={{ fontSize: "14px", color: "#fff" }}
                        >
                          {orderData?.order_status?.status_description}
                        </SoftTypography>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                {["vendor"].includes(role) && (
                  <Grid item xs={12} md={12} xl={4}>
                    <Card
                      sx={{
                        borderRadius: "12px",
                        padding: 3,
                        background: "linear-gradient(135deg, #4cb8c4, #3cd3ad)", // Gradient Background
                        color: "white",
                        boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
                        textAlign: "center",
                        flexDirection: "column",
                        height: "100%", // Force equal height
                      }}
                    >
                      {/* Title */}
                      <Grid item xs={12}>
                        <SoftTypography
                          variant="h5"
                          fontWeight="bold"
                          mb={1}
                          color="white"
                        >
                          {orderData?.order_title}
                        </SoftTypography>
                      </Grid>

                      {/* Status */}
                      <Grid item xs={12} mb={1}>
                        <SoftTypography
                          variant="h4"
                          fontWeight="bold"
                          sx={{
                            fontSize: "22px",
                            fontWeight: "medium",
                            padding: "5px 15px",
                            backgroundColor: "white",
                            color: "rgba(0, 0, 0, 0.87)",
                            borderRadius: "20px",
                            textAlign: "center",
                            display: "inline-block",
                            position: "relative",
                            "&::before": {
                              content: '"-> "',
                              position: "absolute",
                              left: "-10px",
                              color: "rgba(0, 0, 0, 0.87)",
                              fontWeight: "bold",
                            },
                            "&::after": {
                              content: '" <-"',
                              position: "absolute",
                              right: "-10px",
                              color: "rgba(0, 0, 0, 0.87)",
                              fontWeight: "bold",
                            },
                          }}
                        >
                          {orderData?.order_status?.status_text}
                        </SoftTypography>
                      </Grid>

                      {/* Action Buttons */}
                      <Grid container spacing={1}>
                      {isVendorOwnOrder ? (
                        <Grid item xs={12}>
                          <SoftButton
                            variant="gradient"
                            color="success"
                            fullWidth
                            size="small"
                            disabled
                            sx={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              padding: "8px",
                            }}
                          >
                            Already Approved
                          </SoftButton>
                        </Grid>
                      ) : (
                        <Grid item xs={12}>
                          <SoftButton
                            variant="gradient"
                            color="success"
                            fullWidth
                            size="small"
                            onClick={() => handleApproval(orderData?.order_number)} // Function to handle approval
                            disabled={isAlreadyApproved}
                            sx={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              padding: "8px",
                            }}
                          >
                            {isAlreadyApproved ? "Already Approved" : "Approve"}
                          </SoftButton>
                        </Grid>
                      )}
                        <Grid item xs={12}>
                          {!isDateApproved && !isDateDeny && (
                            <SoftButton
                              variant="gradient"
                              color="info"
                              fullWidth
                              size="small"
                              onClick={openChangeRequestModal} // Function to handle change request
                              sx={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                padding: "8px",
                              }}
                            >
                              Change Request
                            </SoftButton>
                          )}

                          {isDateApproved && (
                            <SoftButton
                              variant="gradient"
                              color="info"
                              fullWidth
                              size="small"
                              disabled
                              sx={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                padding: "8px",
                              }}
                            >
                              Date Approved
                            </SoftButton>
                          )}

                          {isDateDeny && (
                            <SoftButton
                              variant="gradient"
                              color="info"
                              fullWidth
                              size="small"
                              disabled
                              sx={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                padding: "8px",
                              }}
                            >
                              Date is Denied
                            </SoftButton>
                            
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <SoftButton
                            variant="gradient"
                            color="error"
                            fullWidth
                            size="small"
                            onClick={() => openCancelDialog(orderData?.order_number)} // Function to handle cancel request
                            sx={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              padding: "8px",
                            }}
                          >
                            Cancel Request
                          </SoftButton>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                )}

                <Dialog open={cancelDialogOpen} onClose={closeCancelDialog}>
                  <DialogTitle>Confirm Cancel Request</DialogTitle>
                  <DialogContent>
                      Are you sure you want to cancel this request.
                  </DialogContent>
                  <DialogActions>
                      <Button onClick={closeCancelDialog} color="primary">
                          Close
                      </Button>
                      <Button onClick={confirmCancelRequest}  color="error">
                          Confirm
                      </Button>
                  </DialogActions>
                </Dialog>

            
                <Dialog open={open}>
                  <DialogTitle>Provide Change Details</DialogTitle>
                  <DialogContent>
                    {/* Date Field */}
                    <TextField
                      label="Date"
                      color="info"
                      type="date"
                      fullWidth
                      value={changeDate}
                      onChange={(e) => setChangeDate(e.target.value)}
                      variant="outlined"
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                    {/* Time Field */}
                    <TextField
                      label="Time"
                      color="info"
                      type="time"
                      fullWidth
                      value={changeTime}
                      onChange={(e) => setChangeTime(e.target.value)}
                      variant="outlined"
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                    {/* Change Detail Field */}
                    <TextField
                      label="Change Detail"
                      color="info"
                      fullWidth
                      value={changeDetail}
                      onChange={(e) => setChangeDetail(e.target.value)}
                      multiline
                      rows={4}
                      variant="outlined"
                      margin="normal"
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={closeChangeRequestModal} color="primary">
                      Close
                    </Button>
                    <Button onClick={() => handleChangeRequest(orderId)} color="primary">
                      Send
                    </Button>
                  </DialogActions>
                </Dialog>



              </Grid>
            </SoftBox>
            {orderData?.deliveries?.length > 0 && (
              <ProjectAccordion 
                data={orderData?.deliveries} 
                statusText={orderData?.order_status?.status_text}
              />
            )}
          </>
        )}
      </Box>
    </DashboardLayout>
  );
}

export default OrderDetail;
