import React, { useState, useEffect, useRef } from "react";
import {
  FormControlLabel,
  Checkbox,
  Grid,
  TextField,
  Box,
  Badge,
  Popover,
  Avatar,
  Typography,
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import { Backdrop } from "@mui/material";
import SoftTypography from "components/SoftTypography";
import { useNavigate, useLocation } from "react-router-dom";
import DefaultLayout from "layouts/authentication/components/DefaultLayout/DefaultLayout";
import SoftBox from "components/SoftBox";
import { Calculate, Map as MapIcon, Edit } from "@mui/icons-material";
import Calculator from "./Calculator";
import Map from "./Map"; // Import your MapComponent
import SoftButton from "components/SoftButton";
import { useTheme } from "@mui/material/styles";
import CastIcon from "@mui/icons-material/Cast";
import SpeedIcon from "@mui/icons-material/Speed";
import ConstructionIcon from "@mui/icons-material/Construction";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CustomizedConcrete from "./Customizedconcretepopup";
import Trackcar from "./Trackcar";
import DirectTip from "./DirectTip";
import PumpMixer from "./PumpMixer";
import SlideCar from "./SlideCar"; // Import your popup components
import { useGlobalState } from "globalState/globalState";
import { fetchVehicleTypes, fetchTypeProject } from "./weatherUtils"; // Correct import statement
import { getCustomConcrete } from 'api/apiService';  // Import the API function
import WeatherConditionsCard from "./components/WeatherConditionsCard";



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const nameTranslation = {
  Garasjegulv: "Garage floor",
  Kjellergulv: "Basement floor",
  Systemelementer: "System elements",
  Veggstøp: "Wall plaster",
  Terrasseplate: "Terrace board",
  Trapper: "Stairs",
  "Annen støp ute": "Another cast outside",
  "Annen støp inne": "Other cast inside",
  Støttemur: "Retaining wall",
};

const names = [
  "Garage floor",
  "Basement floor",
  "System elements",
  "Wall plaster",
  "Terrace board",
  "Stairs",
  "Another cast outside",
  "Other cast inside",
  "Retaining wall",
];

const getStyles = (name, selectedName, theme) => {
  return {
    fontWeight:
      selectedName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
};

const vehicleTypeMapping = {
  trackcar: "40",      // 16m Track car
  directTip: "38",     // 1m Direct tip
  pumpMixer: "41",     // 24m Pump mixer
  slideCar: "39",      // 9m Slide car
};

// Main component
const CalculateArea = () => {
  const location = useLocation();
  const { address, dateTimeFields = [], weatherData = [] } = location.state || {};
  const [showDateTime, setShowDateTime] = useState(true);
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [showMapPopup, setShowMapPopup] = useState(false);
  const [totalArea, setTotalArea] = useState(0);
  const [calculatorArea, setCalculatorArea] = useState(0);
  const [mapArea, setMapArea] = useState(0);
  const [personName, setPersonName] = useState("");
  const selectRef = useRef(null);
  const [castWithDrop, setCastWithDrop] = useState(false);
  const [delay, setDelay] = useState(false);
  const [steelFiber, setSteelFiber] = useState(false);
  const [accelerator, setAccelerator] = useState(false);
  const [uploadingTypeChecked, setUploadingTypeChecked] = useState(false);
  const [showProPopup, setShowProPopup] = useState(false);
  const [openPopup, setOpenPopup] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [concreteConfig, setConcreteConfig] = useState(null);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const { dispatch } = useGlobalState();
  const [vehicleType, setVehicleType] = useState("40");
  const [projectTypes, setProjectTypes] = useState([]); // Store fetched project types
  const [selectedProjectType, setSelectedProjectType] = useState(""); // Default to ID "1"
  const [showAdditionalOptions, setShowAdditionalOptions] = useState(false);
  const [addAmountChecked, setAddAmountChecked] = useState(false); // Default checked
  const [pumpMixerWashChecked, setPumpMixerWashChecked] = useState(false); // Default checked
  const [addHoseChecked, setAddHoseChecked] = useState(false); // Default unchecked
  const [hoseAmount, setHoseAmount] = useState(0); // For hose input value
  const [concreteConfigData, setConcreteConfigData] = useState(null);

  useEffect(() => {
    console.log(`Address: ${address}`);
  }, [address]);

  console.log('vehicleType:', vehicleType);
  console.log('selectedProjectType:', selectedProjectType);
  console.log('personName:', personName);
  console.log('selectedItem:', selectedItem);
  console.log('pumpMixerWashChecked:', pumpMixerWashChecked);
  console.log('addAmountChecked:', addAmountChecked);
  console.log('addHoseChecked:', addHoseChecked);
  console.log('hoseAmount:', hoseAmount);
  const { concrete_quality, concrete_stone, concrete_reduction, concrete_consistency } = concreteConfigData || {
    concrete_quality: [],
    concrete_stone: [],
    concrete_reduction: [],
    concrete_consistency: []
  };
  console.log('concreteConfig:', concreteConfig);
  console.log('concreteConfigData:', concreteConfigData);
  console.log('concrete_quality:', concrete_quality);
  const handleCheckboxChange = (event) => {
    setShowDateTime(event.target.checked);
  };

  const handleOpenPopup = () => {
    setShowPopup(true);
  };

  const handleOpenMapPopup = () => {
    setShowMapPopup(true);
  };

  const handleMapAreaChange = (area) => {
    setMapArea(area);
    setTotalArea(0);
  };

  const handleCalculatorAreaChange = (area) => {
    setCalculatorArea(area);
    setTotalArea(0);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setShowMapPopup(false);
  };

  const handleOpenSpecificPopup = (popupName) => {
    setOpenPopup(popupName);
  };

  const handleCloseSpecificPopup = () => {
    setOpenPopup(null);
  };

  const handleResetArea = () => {
    setTotalArea(0);
    setCalculatorArea(0);
    setMapArea(0); // Ensure all related areas are reset
  };

  const calcl = parseFloat(calculatorArea) + parseFloat(mapArea) + parseFloat(totalArea) || 0
  const combinedTotalArea = calcl;


  
  const handleBoxClick = () => {
    handleCheckboxChange({ target: { name: "addPumpWaste", checked: !showDateTime } });
  };

  const handleCheckboxClick = (event) => {
    event.stopPropagation();
    handleCheckboxChange(event);
  };

  

  useEffect(() => {
    const fetchProjectTypes = async () => {
      try {
        const response = await fetchTypeProject(); // Fetch project types
        if (response?.results) {
          setProjectTypes(response.results); // Store the fetched data
          
        }
      } catch (error) {
        console.error("Error fetching project types:", error);
      }
    };

    fetchProjectTypes();
  }, [selectedProjectType]);

  useEffect(() => {
    const fetchCustomConcrete = async () => {
      try {
        const response = await getCustomConcrete(); // Make the API call
        if (response) {
          setConcreteConfigData(response); // Assuming 'data' holds the concrete config
        } else {
          throw new Error('Failed to fetch concrete configuration');
        }
      } catch (error) {
        console.error("Error fetchCustomConcrete:", error);
        setConcreteConfigData(null); // Handle error, maybe set to null or keep the old data
      }
    };

    fetchCustomConcrete();
  }, []);

  // Handle selection change
  const handleProjectTypeChange = (event) => {
    const selectedId = event.target.value; // Get the selected ID
    setSelectedProjectType(selectedId);
    
    const matchedProject = projectTypes.find((type) => type.id === selectedId);

    // Update personName with the matched name
    setPersonName(matchedProject ? matchedProject.name : "");

  };

  const handleInputClick = () => {
    selectRef.current && selectRef.current.click();
  };

  const handleUploadingTypeCheckboxChange = (event) => {
    setUploadingTypeChecked(event.target.checked);
  };

  const handleProClick = () => {
    setShowProPopup(true);
  };

  const handleCloseProPopup = () => {
    setShowProPopup(false);
  };

  const handleProSave = (data) => {
    console.log(data);
    setConcreteConfig(data);
    setShowProPopup(false);
  };

  

  const handleItemClick = (item) => {
    setSelectedItem(item);
    if (item === "pumpMixer") {
      setShowAdditionalOptions(true);
      setSelectedItem(item);  // Set the selected item
      setVehicleType(vehicleTypeMapping[item]);  // Update vehicle type based on selection
      setAddAmountChecked(true);
      setPumpMixerWashChecked(true);
    } else if (selectedItem === item) {
      setSelectedItem(null);  // Deselect if already selected
      setVehicleType("40");   // Default to 40 if no item selected
    } else {
      setShowAdditionalOptions(false);
      resetAdditionalOptions(); // Reset checkboxes and input when switching
      setSelectedItem(item);  // Set the selected item
      setVehicleType(vehicleTypeMapping[item]);  // Update vehicle type based on selection
    }
  };

  const resetAdditionalOptions = () => {
    setAddAmountChecked(false);
    setPumpMixerWashChecked(false);
    setAddHoseChecked(false);
    setHoseAmount(0);
  };

  const handleInputClick2 = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(true);
  };
  const handleNextClick = () => {
    const step1Data = {
      address,
      dateTimeFields,
      combinedTotalArea,
      personName,
      concreteConfig,
      concreteConfigData,
      castWithDrop,
      delay,
      steelFiber,
      accelerator,
      uploadingTypeChecked,
      selectedItem,
      vehicleType, // Pass the vehicleType here
      selectedProjectType,
      weatherData, // Add this line
      pumpMixerWashChecked,
      addAmountChecked,
      addHoseChecked,
      hoseAmount,
    };
    console.log('step1Data',step1Data);

    navigate("/summary", { state: step1Data });
  };

  const isDayTime = (time) => {
    const hour = parseInt(time.split(":")[0], 10);
    return hour >= 6 && hour < 18;
  };

  const extraContent = (
    <Box
      sx={{
        // height: "100%",
        // minHeight: "600px !important",
        backgroundColor: "#0c97ee",
        paddingLeft: "20px",
        marginTop: "250px",
      }}
    >
      <Grid container direction="column" gap={2}>
        <Typography color={"#fff"} variant="body2">
          <span style={{ fontWeight: "900", fontSize: "20px" }}>Step 1</span> Select Address
        </Typography>
        <Box
          p={2}
          sx={{
            border: "1px solid #fff",
            width: "75%",
            backgroundColor: "#0c97ee",
            borderRadius: "10px",
          }}
        >
          <Typography color={"#fff"} variant="body2">
            <span style={{ fontWeight: "800" }}>Deliver to:</span> {address}
          </Typography>
          {dateTimeFields &&
            dateTimeFields.map((field, index) => {
              const customDateFormat = `${field.date.split("-")[2]}/${field.date.split("-")[1]}/${
                field.date.split("-")[0]
              }`;
              return (
                <>
                  {field.date && field.time ? (
                    // <Box key={index} color="#fff" mt={2} p={2} bgcolor="#333" borderRadius={4}>
                    //   <Typography variant="body2">
                    //     On {field.date}, {field.time}
                    //   </Typography>
                    //   <Grid container spacing={2} alignItems="center">
                    //     <Grid item>
                    //       {weatherData[index]?.weather?.icon && (
                    //         <Avatar
                    //           src={`https://api.met.no/images/weathericons/svg/${weatherData[index].weather.icon}.svg`}
                    //           alt="Weather Icon"
                    //           sx={{ width: 24, height: 24 }}
                    //         />
                    //       )}
                    //     </Grid>
                    //     <Grid item>
                    //       <Typography variant="body2">
                    //         Temperature: {weatherData[index]?.weather?.temperature}°C
                    //       </Typography>
                    //     </Grid>
                    //     <Grid item>
                    //       <Typography variant="body2">
                    //         Air Pressure: {weatherData[index]?.weather?.air_pressure} hPa
                    //       </Typography>
                    //     </Grid>
                    //     <Grid item>
                    //       <Typography variant="body2">
                    //         Cloud Area Fraction: {weatherData[index]?.weather?.cloud_area_fraction}%
                    //       </Typography>
                    //     </Grid>
                    //     <Grid item>
                    //       <Typography variant="body2">
                    //         Relative Humidity: {weatherData[index]?.weather?.relative_humidity}%
                    //       </Typography>
                    //     </Grid>
                    //     <Grid item>
                    //       <Typography variant="body2">
                    //         Wind Direction: {weatherData[index]?.weather?.wind_from_direction}°
                    //       </Typography>
                    //     </Grid>
                    //     <Grid item>
                    //       <Typography variant="body2">
                    //         Wind Speed: {weatherData[index]?.weather?.wind_speed} m/s
                    //       </Typography>
                    //     </Grid>
                    //   </Grid>
                    // </Box>
                    <WeatherConditionsCard
                      key={index}
                      index={index}
                      date={customDateFormat}
                      time={field.time}
                      weatherForDate={weatherData[index].weather}
                    />
                  ) : null}
                </>
              );
            })}
        </Box>
        <Typography color={"#fff"} variant="body2">
          <span style={{ fontWeight: "900", fontSize: "20px" }}>Step 2</span> Delivery
          configurations
        </Typography>
        <Box
          p={2}
          sx={{
            border: "1px solid #fff",
            width: "75%",
            backgroundColor: "#0c97ee",
            borderRadius: "10px",
          }}
        >
          <Typography color={"#fff"} variant="body2">
            <span style={{ fontWeight: "800" }}>Total Area:</span> {`${combinedTotalArea.toFixed(2)} Square Meters`}
          </Typography>
          <Typography color={"#fff"} variant="body2">
            <span style={{ fontWeight: "800" }}>Project Type:</span> {personName}
          </Typography>
          <Typography color={"#fff"} variant="body2">
            <span style={{ fontWeight: "800" }}>Custom Concrete:</span> {concreteConfig?.[0]?.name || "N/A"}
          </Typography>
          {concreteConfig?.[0] && (
            <Box ml={2}>
              <Typography color={"#fff"} variant="body2">
                <span style={{ fontWeight: "800" }}>Quality:</span> {concrete_quality.find(q => q.id === parseInt(concreteConfig[0].quality))?.name || 'N/A'}
              </Typography>
              <Typography color={"#fff"} variant="body2">
                <span style={{ fontWeight: "800" }}>Stone Type:</span> {concrete_stone.find(s => s.id === parseInt(concreteConfig[0].stone))?.name || 'N/A'}
              </Typography>
              <Typography color={"#fff"} variant="body2">
                <span style={{ fontWeight: "800" }}>Stone Reduction:</span> {concrete_reduction.find(r => r.id === parseInt(concreteConfig[0].reduction))?.name || 'N/A'}
              </Typography>
              <Typography color={"#fff"} variant="body2">
                <span style={{ fontWeight: "800" }}>Consistency:</span> {concrete_consistency.find(c => c.id === parseInt(concreteConfig[0].consistency))?.name || 'N/A'}
              </Typography>
              <Typography color={"#fff"} variant="body2">
                <span style={{ fontWeight: "800" }}>Additional Choices:</span>
              </Typography>
              <Box ml={2}>
                {concreteConfig[0].lowCarbon && (
                  <Typography color={"#fff"} variant="body2">
                    <span style={{ fontWeight: "800" }}>Low Carbon:</span> Yes
                  </Typography>
                )}
                {concreteConfig[0].curbstone && (
                  <Typography color={"#fff"} variant="body2">
                    <span style={{ fontWeight: "800" }}>Soil Moist:</span> Yes
                  </Typography>
                )}
                {concreteConfig[0].frostProof && (
                  <Typography color={"#fff"} variant="body2">
                    <span style={{ fontWeight: "800" }}>Frostproof:</span> Yes
                  </Typography>
                )}
                {concreteConfig[0].farming && (
                  <Typography color={"#fff"} variant="body2">
                    <span style={{ fontWeight: "800" }}>Livestock Farming:</span> Yes
                  </Typography>
                )}
              </Box>
            </Box>
          )}
          {castWithDrop && (
            <Typography color={"#fff"} variant="body2">
              <span style={{ fontWeight: "800" }}>Cast With Drop:</span> Yes
            </Typography>
          )}
          {delay && (
            <Typography color={"#fff"} variant="body2">
              <span style={{ fontWeight: "800" }}>Delay:</span> Yes
            </Typography>
          )}
          {steelFiber && (
            <Typography color={"#fff"} variant="body2">
              <span style={{ fontWeight: "800" }}>Steel Fiber:</span> Yes
            </Typography>
          )}
          {accelerator && (
            <Typography color={"#fff"} variant="body2">
              <span style={{ fontWeight: "800" }}>Accelerator:</span> Yes
            </Typography>
          )}
          <Typography color={"#fff"} variant="body2">
            <span style={{ fontWeight: "800" }}>Select Uploading Type:</span> {uploadingTypeChecked ? "Yes" : "No"}
          </Typography>
          {uploadingTypeChecked && (
            <> 
              {selectedItem === "trackcar" && (
                <Typography color={"#fff"} variant="body2">
                  <span style={{ fontWeight: "800" }}>16m Track Car:</span> Yes
                </Typography>
              )}
              {selectedItem === "directTip" && (
                <Typography color={"#fff"} variant="body2">
                  <span style={{ fontWeight: "800" }}>1m Direct Tip:</span> Yes
                </Typography>
              )}
              {selectedItem === "pumpMixer" && (
                <Box>
                  <Typography color={"#fff"} variant="body2"><span style={{ fontWeight: "800" }}>24m Pump Mixer:</span> Yes</Typography>
                  {addAmountChecked && (
                    <Typography color={"#fff"} variant="body2"><span style={{ fontWeight: "800" }}>Pumpmixer Added: </span> Yes</Typography>
                  )}
                  {pumpMixerWashChecked && (
                    <Typography color={"#fff"} variant="body2"><span style={{ fontWeight: "800" }}>Pumpwash: </span> Yes</Typography>
                  )}
                  {addHoseChecked && (
                    <Typography color={"#fff"} variant="body2"><span style={{ fontWeight: "800" }}>Hose Amount: </span> {hoseAmount} meters</Typography>
                  )}
                </Box>
              )}
              {selectedItem === "slideCar" && (
                <Typography color={"#fff"} variant="body2">
                  <span style={{ fontWeight: "800" }}>9m Slide Car:</span> Yes
                </Typography>
              )}
            </>
          )}
        </Box>
      </Grid>
    </Box>
  );

  return (
    <DefaultLayout extraContent={extraContent}>
      <Grid container spacing={2} gap={3} px={{ xs: 2, md: 20 }} mt={5} mb={10}>
        <Grid item xs={12}>
          <SoftBox p={1} sx={{ color: "info.main" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SoftTypography
                  variant="h4"
                  color="black"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  {address || ""}
                  {address && (
                    <Edit
                      sx={{ color: "black", marginLeft: 3, cursor: "pointer" }}
                      onClick={() => navigate("/search-address")}
                    />
                  )}
                </SoftTypography>
              </Grid>
              <Grid item xs={12} md={6}>
                <SoftBox sx={{ padding: "0px" }}>
                  <Box
                    sx={{
                      width: "100%",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#fff",
                      border: "1px solid #dee0e2",
                      padding: "8px",
                      borderRadius: "4px",
                      gap: "8px",
                      paddingLeft: 1,
                      "&:hover": {
                        border: "1px solid black",
                      },
                    }}
                    className="calculate_box"
                    onClick={handleBoxClick}
                  >
                    <Avatar
                      sx={{ color: "#fff", bgcolor: "#0c97ee", width: "30px", height: "30px" }}
                    >
                      <Calculate />
                    </Avatar>
                    <Typography
                      fontSize="1rem"
                      sx={{ textTransform: "capitalize", color: "black" }}
                      variant="h5"
                    >
                      Calculate Area
                    </Typography>
                    <FormControlLabel
                      sx={{ marginLeft: "auto" }}
                      control={
                        <Checkbox
                          checked={showDateTime}
                          onChange={handleCheckboxClick}
                          name="addPumpWaste"
                          sx={{
                            "&:hover": {
                              border: "1px solid black",
                            },
                            "&:before": {
                              content: '""',
                              display: "inline-block",
                              width: "16px",
                              height: "16px",
                              border: "1px solid #dee0e2",
                              borderRadius: "4px",
                              backgroundColor: "#fff",
                              marginRight: "8px",
                              WebkitAppearance: "none",
                              MozAppearance: "none",
                              appearance: "none",
                            },
                            "&:checked:before": {
                              borderColor: "#21B4FD",
                            },
                          }}
                        />
                      }
                    />
                  </Box>
                </SoftBox>
              </Grid>
            </Grid>

            {showDateTime && (
              <>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  

                  <Grid item xs={12} sm={10} md={8}>
                    <TextField
                      variant="outlined"
                      placeholder="Area in M3"
                      value={
                          combinedTotalArea % 1 !== 0 // Check if the value has decimals
                            ? combinedTotalArea.toFixed(2) // If decimal, use toFixed
                            : combinedTotalArea // Else, show as is
                        }
                      onChange={(e) => setTotalArea(e.target.value)} // Update state with user input
                      fullWidth
                      InputProps={{
                        sx: {
                          height: "47px !important",
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <Edit
                              sx={{ 
                                cursor: "pointer", 
                                color: "#0c97ee",
                                width: "25px", // Set width for larger size
                                height: "25px", // Set height for larger size
                              }}
                              onClick={handleResetArea} // Set the input value to 0 when the icon is clicked
                            />
                          </InputAdornment>
                        ),
                      }}
                      className="customInput"
                    />
                  </Grid>



                  <Grid item xs={6} sm={1} md={2}>
                    <SoftButton
                      variant="gradient"
                      color="info"
                      fullWidth
                      onClick={handleOpenPopup}
                      title="Calculator"
                      sx={{
                        width: "100%",
                        height: "45px",
                        borderRadius: "7px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        transition: "box-shadow 0.3s ease",
                        cursor: "pointer",
                      }}
                    >
                      <Badge
                        sx={{ color: "#fff" }}
                        badgeContent={<Calculate sx={{ width: "30px", height: "30px" }} />}
                      />
                    </SoftButton>
                  </Grid>

                  <Grid item xs={6} sm={1} md={2}>
                    <SoftButton
                      variant="gradient"
                      color="info"
                      fullWidth
                      title="Map"
                      onClick={handleOpenMapPopup}
                      sx={{
                        width: "100%",
                        height: "45px",
                        borderRadius: "7px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        transition: "box-shadow 0.3s ease",
                        cursor: "pointer",
                      }}
                    >
                      <Badge
                        sx={{ color: "#fff" }}
                        badgeContent={<MapIcon sx={{ width: "30px", height: "30px" }} />}
                      />
                    </SoftButton>
                  </Grid>
                </Grid>
              </>
            )}
          </SoftBox>

          {/* Calculator Popup */}
          <Popover
            open={showPopup}
            onClose={handleClosePopup}
            anchorEl={document.body}
            anchorOrigin={{ vertical: "center", horizontal: "center" }}
            transformOrigin={{ vertical: "center", horizontal: "center" }}
            PaperProps={{
              sx: {
                backgroundColor: "#ffffff",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                border: "none",
                outline: "none",
                width: {
                  xs: "100%", // full width on mobile
                  md: "650px", // fixed width on medium and larger
                },
                height: {
                  xs: "100vh", // full height on mobile
                  md: "auto", // auto height on medium and larger
                },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflowY: "auto",
              },
            }}
            BackdropProps={{
              sx: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                overflowY: "auto",
              }}
            >
              <Calculator onAreaChange={handleCalculatorAreaChange} onClose={handleClosePopup} />
            </Box>
          </Popover>

          {/* Map Popup */}
          <Popover
            open={showMapPopup}
            onClose={handleClosePopup}
            anchorEl={null}
            anchorOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            PaperProps={{
              sx: {
                backgroundColor: "#ffffff",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                border: "none",
                outline: "none",
                width: "800px",
                height: "730px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                overflow: "hidden",
              },
            }}
            BackdropProps={{
              sx: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
            }}
          >
            <Map address={address} onAreaChange={handleMapAreaChange} onSave={handleClosePopup} />
          </Popover>

          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ m: 1, display: "flex", justifyItems: "start" }}>
              <Typography
                variant="h4"
                sx={{ display: "flex", justifyContent: "center", color: "black" }}
              >
                <span style={{ color: "#0c97ee", marginRight: "5px" }}>WHAT</span> are you going to
                cast <span style={{ color: "#0c97ee", marginLeft: "12px" }}>?</span>
              </Typography>
            </Grid>
            <Grid item xs={9} md={10}>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <Select
                  displayEmpty
                  value={selectedProjectType}
                  onChange={handleProjectTypeChange}
                  input={<OutlinedInput onClick={handleInputClick2} />}
                  sx={{
                    height: "45px !important",
                    cursor: "pointer",
                  }}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>Select Type Project</em>;
                    }
                    const selectedItem = projectTypes.find((type) => type.id === selected);
                    return selectedItem ? selectedItem.name : "";
                  }}
                  
                  inputProps={{ "aria-label": "Without label" }}
                  ref={selectRef}
                  open={open}
                  onClose={handleClose}
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpen(!open);
                  }}
                >
                  <MenuItem disabled value="">
                    <em>Select Type Project</em>
                  </MenuItem>
                  {projectTypes.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3} md={2} mt={1}>
              <SoftButton
                variant="gradient"
                color="info"
                fullWidth
                sx={{
                  width: "100%",
                  height: "43px !important",
                  borderRadius: "7px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={handleProClick}
              >
                Pro
              </SoftButton>
            </Grid>
            <Backdrop
              open={showProPopup}
              sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            />
            <Popover
              open={showProPopup}
              onClose={handleCloseProPopup}
              anchorEl={null}
              anchorOrigin={{
                vertical: "center",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "center",
              }}
              PaperProps={{
                sx: {
                  backgroundColor: "#ffffff",
                  padding: "20px",
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                  border: "none",
                  outline: "none",
                  width: {
                    xs: "100%", // full width on mobile
                    md: "800px", // fixed width on medium and larger
                  },
                  height: {
                    xs: "100vh", // full height on mobile
                    md: "600px", // fixed height on medium and larger
                  },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                },
              }}
            >
              <CustomizedConcrete onAreaChange={handleProSave} onClose={handleCloseProPopup} data={concreteConfigData} />
            </Popover>

            <Grid container spacing={2} m={1}>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    width: "100%",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#fff",
                    border: "1px solid #dee0e2",
                    padding: "8px",
                    borderRadius: "4px",
                    gap: "8px",
                    paddingLeft: 1,
                    "&:hover": {
                      border: "1px solid black",
                    },
                  }}
                  className="calculate_box"
                  onClick={() => setCastWithDrop(!castWithDrop)}
                >
                  <Avatar sx={{ color: "#fff", bgcolor: "#0c97ee", width: "30px",height: "30px",}}>
                    <CastIcon />
                  </Avatar>
                  <Typography
                    fontSize="1rem"
                    sx={{ textTransform: "capitalize", color: "#000", marginLeft: 1 }}
                    variant="h5"
                  >
                    Cast With Drop
                  </Typography>
                  <FormControlLabel
                    sx={{ marginLeft: "auto" }}
                    control={
                      <Checkbox
                        checked={castWithDrop}
                        onChange={() => setCastWithDrop(!castWithDrop)}
                      />
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    cursor: "pointer",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#fff",
                    border: "1px solid #dee0e2",
                    padding: "8px",
                    borderRadius: "4px",
                    gap: "8px",
                    paddingLeft: 1,
                    "&:hover": {
                      border: "1px solid black",
                    },
                  }}
                  className="calculate_box"
                  onClick={() => setDelay(!delay)}
                >
                  <Avatar sx={{ color: "#fff", bgcolor: "#0c97ee", width: "30px",height: "30px", }}>
                    <AccessTimeIcon />
                  </Avatar>
                  <Typography
                    fontSize="1rem"
                    sx={{ textTransform: "capitalize", color: "#000", marginLeft: 1 }}
                    variant="h5"
                  >
                    Delay
                  </Typography>
                  <FormControlLabel
                    sx={{ marginLeft: "auto" }}
                    control={<Checkbox checked={delay} onChange={() => setDelay(!delay)} />}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    cursor: "pointer",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#fff",
                    border: "1px solid #dee0e2",
                    padding: "8px",
                    borderRadius: "4px",
                    gap: "8px",
                    paddingLeft: 1,
                    "&:hover": {
                      border: "1px solid black",
                    },
                  }}
                  className="calculate_box"
                  onClick={() => setSteelFiber(!steelFiber)}
                >
                  <Avatar sx={{ color: "#fff", bgcolor: "#0c97ee", width: "30px",height: "30px",}}>
                    <ConstructionIcon />
                  </Avatar>
                  <Typography
                    fontSize="1rem"
                    sx={{ textTransform: "capitalize", color: "#000", marginLeft: 1 }}
                    variant="h5"
                  >
                    Steel Fiber
                  </Typography>
                  <FormControlLabel
                    sx={{ marginLeft: "auto" }}
                    control={
                      <Checkbox checked={steelFiber} onChange={() => setSteelFiber(!steelFiber)} />
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    cursor: "pointer",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#fff",
                    border: "1px solid #dee0e2",
                    padding: "8px",
                    borderRadius: "4px",
                    gap: "8px",
                    paddingLeft: 1,
                    "&:hover": {
                      border: "1px solid black",
                    },
                  }}
                  className="calculate_box"
                  onClick={() => setAccelerator(!accelerator)}
                >
                  <Avatar sx={{ color: "#fff", bgcolor: "#0c97ee", width: "30px",height: "30px",}}>
                    <SpeedIcon />
                  </Avatar>
                  <Typography
                    fontSize="1rem"
                    sx={{ textTransform: "capitalize", color: "#000", marginLeft: 1 }}
                    variant="h5"
                  >
                    Accelerator
                  </Typography>
                  <FormControlLabel
                    sx={{ marginLeft: "auto" }}
                    control={
                      <Checkbox
                        checked={accelerator}
                        onChange={() => setAccelerator(!accelerator)}
                      />
                    }
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ m: 1, display: "flex", justifyItems: "start" }}>
              <SoftTypography
                variant="h4"
                sx={{ display: "flex", justifyContent: "center", color: "#000" }}
              >
                <span style={{ color: "#0c97ee", marginRight: "12px" }}>HOW</span> can we deliver{" "}
                <span style={{ color: "#0c97ee", marginLeft: "12px" }}>?</span>
              </SoftTypography>
            </Grid>
            <Grid item xs={12} md={6} mx={1}>
              <Box
                sx={{
                  cursor: "pointer",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#fff",
                  border: "1px solid #dee0e2",
                  padding: "8px",
                  borderRadius: "4px",
                  gap: "8px",
                  paddingLeft: 1,
                  "&:hover": {
                    border: "1px solid black",
                  },
                }}
                className="calculate_box"
                onClick={() => setUploadingTypeChecked(!uploadingTypeChecked)}
              >
                <Avatar sx={{ color: "#fff", bgcolor: "#0c97ee", width: "30px",height: "30px",}}>
                  <CloudUploadIcon />
                </Avatar>
                <Typography
                  fontSize="1rem"
                  sx={{ textTransform: "capitalize", color: "#000" }}
                  variant="h5"
                >
                  Select track Type
                </Typography>
                <FormControlLabel
                  sx={{ marginLeft: "auto" }}
                  control={
                    <Checkbox
                      checked={uploadingTypeChecked}
                      onChange={() => setUploadingTypeChecked(!uploadingTypeChecked)}
                      name="uploadingType"
                      sx={{
                        "&:hover": {
                          border: "1px solid black",
                        },
                        "&:before": {
                          content: '""',
                          display: "inline-block",
                          width: "16px",
                          height: "16px",
                          border: "1px solid #dee0e2",
                          borderRadius: "4px",
                          backgroundColor: "#fff",
                          marginRight: "8px",
                          WebkitAppearance: "none",
                          MozAppearance: "none",
                          appearance: "none",
                        },
                        "&:checked:before": {
                          borderColor: "#21B4FD",
                        },
                      }}
                    />
                  }
                />
              </Box>
            </Grid>
            {uploadingTypeChecked && (
              <>
                <Grid container spacing={2} m={1}>
                  <Grid item xs={12} md={6} display={"flex"} alignItems={"center"} gap={2}>
                    <Box
                      sx={{
                        cursor: "pointer",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#fff",
                        border: "1px solid #dee0e2",
                        padding: "8px",
                        borderRadius: "4px",
                        gap: "8px",
                        paddingLeft: 1,
                        "&:hover": {
                          border: "1px solid black",
                        },
                      }}
                      className="calculate_box"
                      onClick={() => handleItemClick("trackcar")}
                    >
                      <Typography
                        fontSize="1rem"
                        sx={{
                          textTransform: "capitalize",
                          color: "#000",
                          marginLeft: 1,
                          "&:hover": { cursor: "hand !important" },
                        }}
                        variant="h5"
                      >
                        16m Track car
                      </Typography>
                      <FormControlLabel
                        sx={{ marginLeft: "auto" }}
                        control={
                          <Checkbox
                            checked={selectedItem === "trackcar"}
                            onChange={() => handleItemClick("trackcar")}
                          />
                        }
                      />
                    </Box>
                    <Typography
                      sx={{ cursor: "pointer" }}
                      fontSize={"20px"}
                      fontWeight={800}
                      onClick={() => handleOpenSpecificPopup("trackcar")}
                    >
                      ?
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} display={"flex"} alignItems={"center"} gap={2}>
                    <Box
                      sx={{
                        cursor: "pointer",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#fff",
                        border: "1px solid #dee0e2",
                        padding: "8px",
                        borderRadius: "4px",
                        gap: "8px",
                        paddingLeft: 1,
                        "&:hover": {
                          border: "1px solid black",
                        },
                      }}
                      className="calculate_box"
                      onClick={() => handleItemClick("directTip")}
                    >
                      <Typography
                        fontSize="1rem"
                        sx={{ textTransform: "capitalize", color: "#000", marginLeft: 1 }}
                        variant="h5"
                      >
                        1m Direct tip
                      </Typography>
                      <FormControlLabel
                        sx={{ marginLeft: "auto" }}
                        control={
                          <Checkbox
                            checked={selectedItem === "directTip"}
                            onChange={() => handleItemClick("directTip")}
                          />
                        }
                      />
                    </Box>
                    <Typography
                      sx={{ cursor: "pointer" }}
                      fontSize={"20px"}
                      fontWeight={800}
                      onClick={() => handleOpenSpecificPopup("directTip")}
                    >
                      ?
                    </Typography>
                  </Grid> 
                  <Grid item xs={12} md={6} display={"flex"} alignItems={"center"} gap={2}>
                    <Box
                      sx={{
                        cursor: "pointer",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#fff",
                        border: "1px solid #dee0e2",
                        padding: "8px",
                        borderRadius: "4px",
                        gap: "8px",
                        paddingLeft: 1,
                        "&:hover": {
                          border: "1px solid black",
                        },
                      }}
                      className="calculate_box"
                      onClick={() => handleItemClick("pumpMixer")}
                    >
                      <Typography
                        fontSize="1rem"
                        sx={{ textTransform: "capitalize", color: "#000", marginLeft: 1 }}
                        variant="h5"
                      >
                        24m Pump mixer
                      </Typography>
                      <FormControlLabel
                        sx={{ marginLeft: "auto" }}
                        control={
                          <Checkbox
                            checked={selectedItem === "pumpMixer"}
                            onChange={() => handleItemClick("pumpMixer")}
                          />
                        }
                      />
                    </Box>
                    <Typography
                      sx={{ cursor: "pointer" }}
                      fontSize={"20px"}
                      fontWeight={800}
                      onClick={() => handleOpenSpecificPopup("pumpMixer")}
                    >
                      ?
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} display={"flex"} alignItems={"center"} gap={2}>
                    <Box
                      sx={{
                        cursor: "pointer",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#fff",
                        border: "1px solid #dee0e2",
                        padding: "8px",
                        borderRadius: "4px",
                        gap: "8px",
                        paddingLeft: 1,
                        "&:hover": {
                          border: "1px solid black",
                        },
                      }}
                      className="calculate_box"
                      onClick={() => handleItemClick("slideCar")}
                    >
                      <Typography
                        fontSize="1rem"
                        sx={{ textTransform: "capitalize", color: "#000", marginLeft: 1 }}
                        variant="h5"
                      >
                        9m Slide car
                      </Typography>
                      <FormControlLabel
                        sx={{ marginLeft: "auto" }}
                        control={
                          <Checkbox
                            checked={selectedItem === "slideCar"}
                            onChange={() => handleItemClick("slideCar")}
                          />
                        }
                      />
                    </Box>
                    <Typography
                      sx={{ cursor: "pointer" }}
                      fontSize={"20px"}
                      fontWeight={800}
                      onClick={() => handleOpenSpecificPopup("slideCar")}
                    >
                      ?
                    </Typography>
                  </Grid>

                  {/* Show additional options if 24m Pump Mixer is selected */}
                  {showAdditionalOptions && (
                    <>

                    <Grid item xs={12} sx={{ m: 1, display: "flex", justifyItems: "start" }}>
                      <SoftTypography
                        variant="h4"
                        sx={{ display: "flex", justifyContent: "center", color: "#000" }}
                      >
                        <span style={{ color: "#0c97ee", marginRight: "12px" }}>Further</span> information needed{" "}
                        <span style={{ color: "#0c97ee", marginLeft: "12px" }}>?</span>
                      </SoftTypography>
                    </Grid>

                    <Grid item xs={12} md={6} display={"flex"} alignItems={"center"} gap={2}>
                      <Box
                        sx={{
                          cursor: "pointer",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#fff",
                          border: "1px solid #dee0e2",
                          padding: "8px",
                          borderRadius: "4px",
                          gap: "8px",
                          paddingLeft: 1,
                          "&:hover": {
                            border: "1px solid black",
                          },
                        }}
                        className="calculate_box"
                        
                      >
                        <Typography
                          fontSize="1rem"
                          sx={{ textTransform: "capitalize", color: "#000", marginLeft: 1 }}
                          variant="h5"
                        >
                          Add Pumpmixer (0.5m³)
                        </Typography>
                        <FormControlLabel
                          sx={{ marginLeft: "auto" }}
                          control={
                            <Checkbox
                              checked={addAmountChecked}
                              onChange={(e) => setAddAmountChecked(e.target.checked)}
                            />
                          }
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6} display={"flex"} alignItems={"center"} gap={2}>
                      <Box
                        sx={{
                          cursor: "pointer",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#fff",
                          border: "1px solid #dee0e2",
                          padding: "8px",
                          borderRadius: "4px",
                          gap: "8px",
                          paddingLeft: 1,
                          "&:hover": {
                            border: "1px solid black",
                          },
                        }}
                        className="calculate_box"
                        
                      >
                        <Typography
                          fontSize="1rem"
                          sx={{ textTransform: "capitalize", color: "#000", marginLeft: 1 }}
                          variant="h5"
                        >
                          Pumpmixer Wash
                        </Typography>
                        <FormControlLabel
                          sx={{ marginLeft: "auto" }}
                          control={
                            <Checkbox
                              checked={pumpMixerWashChecked}
                            onChange={(e) => setPumpMixerWashChecked(e.target.checked)}
                            />
                          }
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6} display={"flex"} alignItems={"center"} gap={2}>
                      <Box
                        sx={{
                          cursor: "pointer",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#fff",
                          border: "1px solid #dee0e2",
                          padding: "8px",
                          borderRadius: "4px",
                          gap: "8px",
                          paddingLeft: 1,
                          "&:hover": {
                            border: "1px solid black",
                          },
                        }}
                        className="calculate_box"
                        
                      >
                        <Typography
                          fontSize="1rem"
                          sx={{ textTransform: "capitalize", color: "#000", marginLeft: 1 }}
                          variant="h5"
                        >
                          Add Hose
                        </Typography>
                        <FormControlLabel
                          sx={{ marginLeft: "auto" }}
                          control={
                            <Checkbox
                              checked={addHoseChecked}
                              onChange={(e) => setAddHoseChecked(e.target.checked)}
                            />
                          }
                        />
                      </Box>
                    </Grid>

                      

                      {/* Show hose amount input if Add Hose is checked */}
                      {addHoseChecked && (

                        <Grid item xs={12} md={6} display={"flex"} alignItems={"center"} gap={2}>
                          <Box
                            sx={{
                              cursor: "pointer",
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: "#fff",
                              border: "1px solid #dee0e2",
                              padding: "6px",
                              borderRadius: "4px",
                              gap: "8px",
                              paddingLeft: 1,
                              "&:hover": {
                                border: "1px solid black",
                              },
                            }}
                            className="calculate_box"
                            
                          >
                            
                            <TextField
                              placeholder="Add hose lenght"
                              value={hoseAmount}
                              onChange={(e) => setHoseAmount(e.target.value)}
                              fullWidth
                              className="customInput"
                            />
                          </Box>
                        </Grid>
                        
                      )}
                    </>
                  )}

                </Grid>

                <Backdrop
                  open={Boolean(openPopup)}
                  sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                  }}
                />
                <Popover
                  open={openPopup === "trackcar"}
                  onClose={handleCloseSpecificPopup}
                  anchorEl={null}
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "center",
                    horizontal: "center",
                  }}
                  PaperProps={{
                    sx: {
                      backgroundColor: "#ffffff",
                      padding: "20px",
                      paddingTop: "0px",
                      borderRadius: "10px",
                      boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                      border: "none",
                      outline: "none",
                      width: "700px",
                      height: "730px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    },
                  }}
                >
                  <Trackcar onClose={handleCloseSpecificPopup} />
                </Popover>
                <Popover
                  open={openPopup === "directTip"}
                  onClose={handleCloseSpecificPopup}
                  anchorEl={null}
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "center",
                    horizontal: "center",
                  }}
                  PaperProps={{
                    sx: {
                      backgroundColor: "#ffffff",
                      padding: "20px",
                      borderRadius: "10px",
                      boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                      border: "none",
                      outline: "none",
                      width: "700px",
                      height: "680px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    },
                  }}
                >
                  <DirectTip onClose={handleCloseSpecificPopup} />
                </Popover>
                <Popover
                  open={openPopup === "pumpMixer"}
                  onClose={handleCloseSpecificPopup}
                  anchorEl={null}
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "center",
                    horizontal: "center",
                  }}
                  PaperProps={{
                    sx: {
                      backgroundColor: "#ffffff",
                      padding: "20px",
                      borderRadius: "10px",
                      boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                      border: "none",
                      outline: "none",
                      width: "700px",
                      height: "100% !important",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    },
                  }}
                >
                  <PumpMixer onClose={handleCloseSpecificPopup} />
                </Popover>
                <Popover
                  open={openPopup === "slideCar"}
                  onClose={handleCloseSpecificPopup}
                  anchorEl={null}
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "center",
                    horizontal: "center",
                  }}
                  PaperProps={{
                    sx: {
                      backgroundColor: "#ffffff",
                      padding: "20px",
                      borderRadius: "10px",
                      boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                      border: "none",
                      outline: "none",
                      width: "700px",
                      height: "750x",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    },
                  }}
                >
                  <SlideCar onClose={handleCloseSpecificPopup} />
                </Popover>
              </>
            )}
          </Grid>
          <Grid container spacing={2} sx={{ mt: 8, justifyContent: "flex-end" }}>
            <Box gap={5} sx={{ display: "flex", justifyContent: "end", mt: 2 }}>
              <Grid item xs={6} md={6}>
                <SoftButton
                  variant="contain"
                  color="info"
                  sx={{
                    height: "45px !important",
                    borderRadius: "7px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#0c97ee",
                    border: "1px solid #0c97ee",
                  }}
                  onClick={() => navigate("/search-address")}
                >
                  Back
                </SoftButton>
              </Grid>
              <Grid item xs={6} md={6}>
                <SoftButton
                  variant="gradient"
                  color="info"
                  sx={{
                    height: "45px !important",
                    borderRadius: "7px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={handleNextClick}
                >
                  Next
                </SoftButton>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </DefaultLayout>
  );
};

export default CalculateArea;
