/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
const currentYear = new Date().getFullYear();
const isLoggedIn = localStorage.getItem('loggedIn');

function Footer() {
  return (
    <SoftBox component="footer">
      <Grid container justifyContent="center" mt={3}>
        <Grid item xs={10} lg={6} xl={12}>
          <SoftBox display="flex" justifyContent="center" flexWrap="wrap">
            {!isLoggedIn ? (
              <SoftBox mr={{ xs: 2, lg: 3, xl: 6 }}>
                <SoftTypography component="a" href="/authentication/log-in" variant="h5" color="#000" sx={{ fontWeight: 'bold', color:"#000" }}>
                  Logg inn
                </SoftTypography>
              </SoftBox>
            ) : (
              <SoftBox mr={{ xs: 2, lg: 3, xl: 6 }}>
                <SoftTypography component="a" href="/dashboard" variant="h5" color="#000" sx={{ fontWeight: 'bold', color:"#000" }}>
                  Dashbord
                </SoftTypography>
              </SoftBox>
            )}
            <SoftBox mr={{ xs: 2, lg: 3, xl: 6 }}>
              <SoftTypography component="a" href="/search-address" variant="h5" color="#000" sx={{ fontWeight: 'bold', color:"#000" }}>
                Kom i gang
              </SoftTypography>
            </SoftBox>
            <SoftBox mr={{ xs: 2, lg: 3, xl: 6 }}>
              <SoftTypography component="a" href="javascript:;" variant="h5" color="#000" sx={{ fontWeight: 'bold', color:"#000" }}>
                Registrer
              </SoftTypography>
            </SoftBox>
            <SoftBox mr={{ xs: 2, lg: 3, xl: 6 }}>
             <SoftTypography component="a" href="https://www.betongsentrum.no/om-oss" target="_blank" rel="noopener noreferrer" variant="h5" color="#000" sx={{ fontWeight: 'bold', color:"#000" }}>
                Om Oss
              </SoftTypography>
            </SoftBox>
            <SoftBox mr={{ xs: 0, lg: 3, xl: 6 }}>
             <SoftTypography component="a" href="https://www.betongsentrum.no/kontakt-oss-2" target="_blank" rel="noopener noreferrer" variant="h5" color="#000" sx={{ fontWeight: 'bold', color:"#000" }}>
                Kontakt
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </Grid>
        <Grid item xs={12} lg={12} xl={12}>
          <SoftBox mt={3} textAlign="center">
            <SoftTypography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.2', color:"#000" }}>
              Powered by BMAX™.
            </SoftTypography>
          </SoftBox>
        </Grid>
        <Grid item xs={12} lg={12} xl={12} sx={{ textAlign: "center" }}>
          <SoftTypography variant="body2" color="#000" sx={{ lineHeight: '1.2', color:"#000" }}>
           Vernet etter Åndsverkloven © {new Date().getFullYear()}
          </SoftTypography>
        </Grid>
        <Grid item xs={12} lg={12} xl={12} sx={{ textAlign: "center" }} mb={2}>
          <SoftTypography variant="body2" color="#000" sx={{ fontWeight: 'bold', lineHeight: '1.2', color:"#000" }}>
           Betongsentrum AS - ORG 921743432 MVA
          </SoftTypography>
        </Grid>
      </Grid>
    </SoftBox>
  );
}


export default Footer;

