// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
import ProTableData from "./ProTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";

import { getOrders } from "api/apiService";
import { useEffect, useState } from "react";
import PropTypes from 'prop-types';  // Import PropTypes

// Pagination Component (could be in a separate file)
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";  // Import CircularProgress for loading spinner

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <SoftBox display="flex" justifyContent="center" alignItems="center" mt={2}>
      <Button onClick={handlePrevious} disabled={currentPage === 1}>
        Previous
      </Button>
      <SoftTypography mx={2}>
        Page {currentPage} of {totalPages}
      </SoftTypography>
      <Button onClick={handleNext} disabled={currentPage === totalPages}>
        Next
      </Button>
    </SoftBox>
  );
};

// Define prop types for Pagination component
Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

function ProTablesMain() {
  const [orderData, setOrderData] = useState({});
  const [currentPage, setCurrentPage] = useState(1); // State for the current page
  const [totalPages, setTotalPages] = useState(1); // State for the total number of pages
  const [loading, setLoading] = useState(false);  // State to track loading status

  const showTable = async (page = 1) => {
    setLoading(true);  // Start loading

    const data = await getOrders({ limit: 5, page_number: page });

    if (data.status) {
      const { columns, rows } = ProTableData(data.data);
      setOrderData({ columns, rows });
      setTotalPages(data.data_info.total_pages); // Set total pages from the response
    }

    setLoading(false);  // Stop loading after data is fetched
    console.log("data order fetch: ", data); // Moved up to log data before the return
  };

  useEffect(() => {
    showTable(currentPage); // Fetch data for the current page
  }, [currentPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <DashboardLayout>
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card sx={{ position: 'relative' }}>
            {/* Overlay Loader */}
            {loading && (
              <SoftBox
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(255, 255, 255, 0.7)', // Semi-transparent overlay
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 9999,
                }}
              >
                <CircularProgress color="info" />
              </SoftBox>
            )}

            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Projects Data</SoftTypography>
            </SoftBox>

            {/* Show Table Data */}
            {orderData.rows && orderData.rows.length === 0 ? (
              <SoftBox display="flex" justifyContent="center" alignItems="center" height="200px">
                <SoftTypography>No records found</SoftTypography>
              </SoftBox>
            ) : (
              <SoftBox
                sx={{
                  "& .MuiTableRow-root:not(:last-child)": {
                    "& td": {
                      borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    },
                  },
                }}
              >
                <Table columns={orderData.columns} rows={orderData.rows} />
              </SoftBox>
            )}

            {/* Add Pagination Component here */}
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </Card>
        </SoftBox>
      </SoftBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default ProTablesMain;
